<template>
     <swiper
    :slides-per-view="1"
    :loop="true"
    :autoplay="true"
    navigation
  >
    <swiper-slide v-for="slide in slides" :key="slide">
          <div class="carousel__item" :style="{ backgroundImage: `url(${slide})` }"> </div>
        </swiper-slide> 
  </swiper> 
</template>
<script>

// import Swiper core and required modules
  import SwiperCore, { Navigation } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/swiper.scss';
  import 'swiper/components/navigation/navigation.scss';

  // install Swiper modules
  SwiperCore.use([Navigation]);


export default {
    name: 'Slider',
    props: ['slides'],
    components: {
      Swiper,
      SwiperSlide,
    },
    methods: {
    
    },
}
</script>
<style lang=scss>


    .swiper-container{


        .swiper-slide{
            display: flex;
            justify-content: center;

            img{
                height: auto;
            }
        }


        .swiper-button-next,.swiper-button-prev{
            color: #cb2a8a;
        }
    }

    .carousel__item{
        width: 100%;
        height: 600px;
        max-height: 50vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;

        @media screen and (min-width: 1200px){
            max-height: 75vh;
        }
    }
</style>