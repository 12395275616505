<template>
  <ContactUs />
  <!-- Footer Section Start -->
  <footer>
    <div class="container">
      <div class="row">
        <!-- Footer Links -->
        <div class="col-lg-6 col-sm-6 col-xs-12">
          <ul class="footer-links">
            <li>
              <a href="#">Homepage</a>
            </li>
            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-sm-6 col-xs-12">
          <div class="copyright">
            <p>All copyrights reserved &copy; 2023</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section End -->

  <!-- Go To Top Link -->
  <a href="#" class="back-to-top">
    <i class="lnr lnr-arrow-up"></i>
  </a>
</template>
<script>
import LanguageSwitcher from "@/components/language-switcher/Language-Switcher.vue";
import ContactUs from "@/components/contact-us/Contact-Us.vue";

export default {
  name: "Footer",
  components: {
    LanguageSwitcher,
    ContactUs
  }
};
</script>
