<template>
  <header class="header">
    <!-- Navbar Start -->
    <nav
      class="navbar navbar-expand-xl fixed-top scrolling-navbar indigo top-nav-collapse"
    >
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <a class="navbar-brand" href="/"
            ><img class="img-fulid" src="/img/buss_pass_logo.png" alt=""
          /></a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="lnr lnr-menu"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="main-navbar">
          <ul class="navbar-nav mr-auto w-100 justify-content-end">
            <li class="nav-item">
              <a class="nav-link page-scroll" href="/">{{
                $t("header.home")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#services">{{
                $t("header.services")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#route">{{
                $t("header.map")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#features">{{
                $t("header.features")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#portfolios">{{
                $t("header.portfolios")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#pricing">{{
                $t("header.info")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#team">{{
                $t("header.team")
              }}</a>
            </li>
            <li class="nav-item" v-if="isHomePage">
              <a class="nav-link page-scroll" href="#contact">{{
                $t("header.contact")
              }}</a>
            </li>
            <li class="nav-item" style="padding: 10px">
              <Language></Language>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import Language from "../language-switcher/Language-Switcher.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "Header",
  components: { Language },
  setup() {
    const route = useRoute();

    const isHomePage = computed(() => {
      return route.name === "Home";
    });

    return {
      isHomePage,
      Language
    };
  }
};
</script>

<style lang="scss">
.slicknav_nav {
  max-height: 430px;
}
</style>
