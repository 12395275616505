export const countryCodePhoneLengthMap = {
  "376": 6,
  "971": 9,
  "93": 9,
  "1-268": 10,
  "1-264": 10,
  "355": 9,
  "374": 6,
  "244": 9,
  "54": [6, 7, 8],
  "1-684": 10,
  "43": [10, 11],
  "61": 9,
  "297": 7,
  "358": 7,
  "994": 9,
  "387": 8,
  "1-246": 10,
  "880": 10,
  "32": 9,
  "226": 8,
  "359": 9,
  "973": 8,
  "257": 8,
  "229": 8,
  "1-441": 10,
  "673": 7,
  "591": 9,
  "55": 11,
  "1-242": 10,
  "975": 7,
  "267": 7,
  "375": 9,
  "501": 7,
  "243": 7,
  "236": 8,
  "242": 9,
  "41": 9,
  "225": 8,
  "682": 5,
  "56": 9,
  "237": 9,
  "86": 11,
  "57": 10,
  "506": 8,
  "53": 8,
  "238": 7,
  "599": 7,
  "357": 8,
  "420": 9,
  "49": 10,
  "253": 10,
  "45": 8,
  "1-767": 10,
  "1-809": 10,
  "213": 9,
  "593": 9,
  "372": 8,
  "20": 10,
  "212": 9,
  "291": 7,
  "34": 9,
  "251": 9,
  "679": 7,
  "691": 7,
  "298": 5,
  "33": 9,
  "241": 7,
  "44": 10,
  "1-473": 10,
  "995": 9,
  "594": 9,
  "233": 9,
  "350": 8,
  "299": 6,
  "220": 7,
  "224": 9,
  "590": 9,
  "240": 9,
  "30": 10,
  "500": 5,
  "502": 8,
  "1-671": 10,
  "245": 9,
  "592": 7,
  "852": 8,
  "672": 10,
  "504": 8,
  "385": 9,
  "509": 8,
  "36": 9,
  "62": 11,
  "353": 9,
  "972": 9,
  "91": 10,
  "246": 7,
  "964": 10,
  "98": 11,
  "354": 7,
  "39": 10,
  "1-876": 10,
  "962": 8,
  "81": 10,
  "254": 10,
  "996": 9,
  "855": 9,
  "686": 8,
  "269": 7,
  "1-869": 10,
  "850": [4, 6, 7, 13],
  "82": [7, 8],
  "965": 8,
  "1-345": 7,
  "856": [8, 9],
  "961": [7, 8],
  "1-758": 7,
  "423": 7,
  "94": 7,
  "231": [8, 9],
  "266": 8,
  "370": 8,
  "352": 9,
  "371": 8,
  "218": 10,
  "377": 8,
  "382": 8,
  "1599": 7,
  "261": 9,
  "692": 7,
  "389": 8,
  "223": 8,
  "95": 8,
  "976": 8,
  "853": 8,
  "1-670": 10,
  "596": 9,
  "222": 8,
  "356": 8,
  "230": 7,
  "960": 7,
  "265": 8,
  "52": 10,
  "60": 9,
  "258": 9,
  "264": 10,
  "687": 6,
  "227": 8,
  "234": 10,
  "505": 8,
  "31": 10,
  "47": 8,
  "977": 10,
  "674": 5,
  "683": 5,
  "64": 8,
  "968": 8,
  "507": 8,
  "51": 9,
  "63": 10,
  "680": 7,
  "675": 7,
  "48": 9,
  "1": 10,
  "974": 8,
  "40": 9,
  "7": 10,
  "250": 9,
  "966": 9,
  "677": 5,
  "248": 7,
  "249": 9,
  "46": 10,
  "65": 8,
  "290": 6,
  "386": 8,
  "421": 9,
  "232": 8,
  "378": 8,
  "221": 9,
  "252": 8,
  "597": 7,
  "503": 8,
  "963": 9,
  "268": 8,
  "1649": 10,
  "239": 9,
  "228": 8,
  "690": 5,
  "676": 5,
  "1868": 10,
  "216": 8,
  "90": 10,
  "688": 5,
  "886": 9,
  "255": 10,
  "380": 9,
  "256": 9,
  "598": 8,
  "998": 9,
  "58": 10,
  "84": 10,
  "678": 5,
  "681": 5,
  "685": 5,
  "381": 9,
  "967": 9,
  "262": 9,
  "27": 9,
  "260": 9,
  "263": 9
};
