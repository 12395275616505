<template>
  <div>
    <div class="container container-tittle">
      <h1>{{ $t("buyTickets.formForPay.title") }}</h1>
    </div>
    <form @submit.prevent="submitForm" class="container">
      <div class="mb-3">
        <label for="firstname" class="form-label">{{
          $t("buyTickets.formForPay.name")
        }}</label>
        <input
          v-model="formData.firstname"
          type="text"
          class="form-control"
          id="firstname"
          required
        />
      </div>
      <div class="mb-3">
        <label for="lastname" class="form-label">{{
          $t("buyTickets.formForPay.surname")
        }}</label>
        <input
          v-model="formData.lastname"
          type="text"
          class="form-control"
          id="lastname"
          required
        />
      </div>

      <div class="mb-3">
        <label for="phoneNumber" class="form-label">{{
          $t("buyTickets.formForPay.telephone")
        }}</label>
        <input
          v-model="formData.phoneNumber"
          type="tel"
          class="form-control"
          id="phoneNumber"
          required
        />
        <p
          v-if="formData.phoneNumber.length > 2 && !isPhoneNumberValid"
          class="text-danger"
        >
          {{ $t("buyTickets.formForPay.errorForPhone") }}
        </p>
      </div>
      <div class="countTicket">
        <div class="mb-3">
          <label for="adultTickets" class="form-label">{{
            $t("buyTickets.formForPay.countSeats[0]")
          }}</label>
          <div class="input-group">
            <div
              class="btn btn-common count"
              @click="decrement('adultTickets')"
            >
              -
            </div>
            <input
              v-model.number="formData.adultTickets"
              type="text"
              class="form-control"
              id="adultTickets"
              required
              readonly
            />
            <div
              class="btn btn-common count"
              :class="{ disable: disablebutton }"
              @click="increment('adultTickets')"
            >
              +
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="childTickets" class="form-label">
            {{ $t("buyTickets.formForPay.countSeats[1]") }}

            <i class="tooltip-trigger"
              >(?)
              <span class="tooltip-data">
                {{ $t("buyTickets.formForPay.childToolTip") }}
              </span>
            </i>
          </label>
          <div class="input-group">
            <div
              class="btn btn-common count"
              @click="decrement('childTickets')"
            >
              -
            </div>
            <input
              v-model.number="formData.childTickets"
              type="text"
              class="form-control"
              id="childTickets"
              min="0"
              readonly
            />
            <div
              class="btn btn-common count"
              :class="{ disable: disablebutton }"
              @click="increment('childTickets')"
            >
              +
            </div>
          </div>
        </div>
      </div>
      <div>
        <input type="checkbox" checked disabled class="mr-2" />
        <span v-html="$t('buyTickets.formForPay.agreeWithTerms')"></span>
      </div>
      <p class="text-danger">
        {{ validateSeats() ? $t("buyTickets.formForPay.maxSeatsError") : null }}
      </p>
      <div class="fotterForm d-flex flex-wrap">
        <p>
          {{ $t("buyTickets.formForPay.countTickets") }}
          {{ chosenTickerCount }}. {{ $t("buyTickets.formForPay.total") }}:
          <span
            >{{ calculateTotal("total") }}
            {{ $t("buyTickets.calendar.currency") }}</span
          >
        </p>
        <div class="buyTicketbutton">
          <button class="btn btn-common buttonBack" @click="onClickToBack">
            {{ $t("buttons.back") }}
          </button>
          <button class="btn btn-common" :class="{ disable: isFormInvalid }">
            {{ $t("buttons.pay") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { validatePhoneLengthByCountryCode } from "@/services/utils";

export default {
  name: "BookingDetailForm",
  components: {},
  props: {
    trip: { type: Object, required: true }
  },
  emits: ["booking-details-changed", "steps-to-back"],
  setup(props, { emit }) {
    const formData = ref({
      firstname: "",
      lastname: "",
      phoneNumber: "+",
      adultTickets: 1,
      childTickets: 0,
      amount: 0
    });
    const disablebutton = ref(false);

    const submitForm = () => {
      if (isFormInvalid.value === false) {
        emit("booking-details-changed", formData.value);
      }
    };

    const onClickToBack = () => {
      emit("steps-to-back");
    };

    const increment = key => {
      if (props.trip.availableSeats === chosenTickerCount.value) return;
      formData.value[key]++;
    };

    const decrement = key => {
      if (formData.value[key] > 0) {
        formData.value[key]--;
      }
    };

    const chosenTickerCount = computed(
      () => formData.value.adultTickets + formData.value.childTickets
    );

    const validateSeats = () => {
      if (props.trip.availableSeats === chosenTickerCount.value) {
        disablebutton.value = true;
        return true;
      } else {
        disablebutton.value = false;
        return false;
      }
    };

    const isPhoneNumberValid = computed(() => {
      const { phoneNumber } = formData.value;

      return (
        phoneNumber.startsWith("+") &&
        validatePhoneLengthByCountryCode(phoneNumber.slice(1).trim())
      );
    });

    const isFormInvalid = computed(() => {
      return (
        formData.value.firstname === "" ||
        formData.value.lastname === "" ||
        formData.value.phoneNumber === "" ||
        isPhoneNumberValid.value === false ||
        (formData.value.adultTickets === 0 && formData.value.childTickets === 0)
      );
    });

    const calculateTotal = type => {
      const adultPrice = props.trip.tripTypeInfo.prices.adult;
      const childPrice = props.trip.tripTypeInfo.prices.child;

      const adultTotal = formData.value.adultTickets * adultPrice;
      const childTotal = formData.value.childTickets * childPrice;
      formData.value.amount = adultTotal + childTotal;

      if (type === "adult") {
        return adultTotal;
      } else if (type === "child") {
        return childTotal;
      } else {
        return adultTotal + childTotal;
      }
    };

    return {
      formData,
      submitForm,
      calculateTotal,
      onClickToBack,
      decrement,
      increment,
      chosenTickerCount,
      validateSeats,
      disablebutton,
      isFormInvalid,
      isPhoneNumberValid
    };
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: #000;
}
.container-tittle {
  background: none;
}

.buttonBack {
  background: #fff;
  color: #f2cb3f;
}
.container {
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 15px;
}
.fotterForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  span {
    font-weight: 600;
  }
}

.buyTicketbutton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-control {
  color: #000;
  border: 1px solid #f2cb3f;
  border-radius: 10px;
}

.countTicket {
  .count {
    background: #cb2a8a;
    border: #cb2a8a;
    font-size: 1.2rem;
    font-weight: 500;
    &:hover {
      background: #f2cb3f;
      border: #f2cb3f;
    }
  }
  input {
    text-align: center;
    border-radius: 0;
    border: none;
    background: none;
    font-size: 1rem;
    max-width: 100px;
  }
}

.disable {
  background: #c6c6c6 !important;
  cursor: default;
  border: 1px solid #c6c6c6;
}

.tooltip {
  &-trigger {
    position: relative;
    color: #cb2a8a;

    &:hover {
      .tooltip-data {
        display: block;
      }
    }
  }

  &-data {
    font-size: 13px;
    color: black;
    text-align: center;
    width: 120px;
    background-color: white;
    border: 1px solid black;
    border-radius: 12px;
    position: absolute;
    top: -15px;
    right: -100px;
    display: none;
  }
}
</style>
