<template>
  <div class="item-boxes wow fadeInDown" data-wow-delay="0.2s">
    <div class="icon">
      <i class="lnr" :class="icon"></i>
    </div>
    <h4>{{ $i18n.locale === "en" ? titleEn : title }}</h4>
    <div
      class="trip-type-description"
      v-html="$i18n.locale === 'en' ? descriptionEn : description"
    ></div>
    <div v-if="actionType" class="mt-2">
      <router-link
        v-if="actionType === 'buy'"
        class="btn btn-common"
        to="/buy-ticket"
        >{{ $t("buttons.buy") }}
      </router-link>
      <a
        v-else-if="actionType === 'reserve'"
        class="btn btn-common"
        href="tel:+380661690379"
      >
        {{ $t("buttons.reserve") }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "TripType",
  props: {
    title: {
      type: String,
      required: true
    },
    titleEn: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    descriptionEn: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      default: null
    }
  }
};
</script>

<style>
.trip-type-description {
  p {
    margin: 0;
  }
}
</style>
