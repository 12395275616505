import { createApp } from "vue";
import App from "./App.vue";
import router from "./services/router";
import { i18n } from "./localization/l18n";
import { initSentry } from "./services/sentry";
import "vue-loading-overlay/dist/css/index.css";

const app = createApp(App);
app
  .use(router)
  .use(i18n)
  .mount("#app");
initSentry(app, router);
