export default {
  buttons: {
    buy: "Купити",
    reserve: "ЗАБРОНЮВАТИ",
    pay: "Оплатити",
    back: "Назад",
    contact: "Зв'язатися"
  },
  tickets: {
    child: "дитячий 7-14 років",
    usual: "стандарт",
    discount: "пільговий"
  },
  header: {
    home: "Головна",
    services: "Програма",
    features: "Запитання",
    map: "Карта",
    portfolios: "Фото",
    info: "Інформація",
    team: "Команда",
    contact: "Контакти",
    personal: "Мої квитки",
    login: "Ввійти",
    logout: "Вийти"
  },
  home: {
    intro: {
      title: "BusPass - найкраща оглядова екскурсія у Вінниці",
      description: `на двоповерховому автобусі-кабріолеті <br> з професійним гідом`
    },
    services: {},
    programs: {
      title: "ПРОГРАМА",
      description: "Розклад оновлюється щопонеділка"
    },
    map: {
      title: "Маршрут наших екскурсій"
    },
    features: {
      title: "Переваги системи Hop-on Hop-off",
      sub_title: `Тур по системі Hop-on hop-off - екскурсія по визначеному маршруті з заздалегідь встановленними
                    зупинками.
                    <br>
                    Протягом строку дії білету ( 48 годин ), його власник може виходити і заходити в автобус необмежену
                    кількість разів.
                    <br>
                    Білет активується після першого використання, а не з моменту купівлі, що дозволяє придбати їх
                    заздалегідь`,
      items: [
        {
          title: "7 зупинок",
          description: "Можливість зайти/вийти на будь-якій зупинці"
        },
        {
          title: "Покупка он-лайн",
          description:
            "Купуй електронний білет на сайті та активуй його в автобусі"
        },
        {
          title: "48 годин дії",
          description:
            "Користуйся автобусом протягом 48ми годин з моменту активації"
        },
        {
          title: "Вільне пересування",
          description:
            "Не залеж від групи як при звичайних автобусних екскурсіях"
        }
      ]
    },
    portfolios: {
      title: "Фото галерея"
    },
    info: {
      currentQuestions: "Актуальні запитання",
      whereStart: "Де починається екскурсія?",
      startTrip: "Початок маршруту навпроти Центрального автовокзалу.",
      wherePay: "Де придбати білет?",
      payWith:
        "Тут на сайті. Також, при наявності вільних місць, можна придбати квитки безпосередньо перед посадкою в автобусі.",
      accessTrip: "Як забронювати місця на другому поверсі?",
      accessTripAnswer:
        "Усі квитки в продажі - це місця на другому поверсі, але без нумерації, посадка вільна",
      currentSchedule: "ЯК ДІЗНАТИСЬ АКТУАЛЬНИЙ РОЗКЛАД?",
      currentScheduleAnswer: `Розклад оновлюється щопонеділка. Інформацію можна знайти в наших соцмережах та на сайті.`,
      usefulInformation: "КОРИСНА ІНФОРМАЦІЯ",
      weOpenforYou: "МИ ВІДКРИТІ ДЛЯ ВАС",
      weOpenforYouContent: `Друзі! Ми вперше запускаємо такий проект, і як і всі, хто
                розвивається, ми не застраховані від помилок.
                <br />
                Якщо ви зіткнулись з поганим сервісом, напишіть нам
                buspassua{'@'}gmail.com і питання буде вирішено в найкоротші
                терміни.
                <br />
                Дякуємо, що ви з нами!`,
      hours: "год",
      km: "км",
      min: "хв",
      durationTrip: "Тривалість поїздки",
      lengthTrip: "Протяжність маршруту",
      StopForPhoto: "Зупинка для фото",
      seatsCount: "Місць на другому поверсі"
    },
    team: {
      title: "Наша команда",
      description:
        "Для того, щоб ваша подорож була безпечною, комфортною, цікавою та веселою, ми зібрали команду справжніх професіоналів! Отже, знайомтесь:",
      members: [
        {
          name: "Гідеса  Альона",
          description: `За 8 років, проведених з мамонтом <span>( не плутати з динозавром )</span> у Вінницькому краєзнавчому музеї <span>( хто ще не був - обов’язково сходіть, він там ще є )</span>, Альона вдосконалила свої знання про Вінницю до найвищого рівня.</br> Обожнює естетику у всьому, фотографувати та фотографуватись. І якщо ви на нашій екскурсії будете без компанії, вона з радістю зробить вам круті фото та сторіс з шикарним видом на місто!`
        },
        {
          name: "Boss Олександр",
          description:
            "Серце і душа нашого проекту. Пройшов шлях від смарта до даблдекера. Їздить на всьому, що заводиться! Має водійський досвід по лівосторонньому русі Великобританії та Японії, серпантину норвежських фьордів та бездоріжжю Хмельницької області. Любить футбол та цукерки «Ромашка»"
        },
        {
          name: "Адміністраторка Тетяна",
          description:
            "Відповідальна за все - від прийому дзвінків до доставки алкоголю на дорослі екскурсії. Не вчила в школі історію, зараз надолужує. Постійно в процесі генерації нових ідей. Трохи повернута на роботі."
        },
        {
          name: "Гідеса Інна",
          description: `Приїхала до нас з гостинної Грузіі, випускниця Інституту історії, етнології та права, а також "Школи вінницького гіда". </br>Однозначно, найвеселіша в нашій команді, легка на підйом, завжди за любий кіпіш та переповнена ідей та енергії. </br> Прекрасно готує, частенько приносить щось смачненьке, за те ми її дуже любимо)`
        }
      ]
    },
    contact: {}
  },
  footer: {
    title: "Зв'яжіться з нами",
    city: "Юридична адреса: м. Вінниця, вул. Аграрна 5/16 21019",
    schedule: "Розклад: щоденно з 8:00 до 20:00"
  },
  buyTickets: {
    accordion: [
      {
        title: "Хто має право на безкоштовний проїзд?",
        content: ` <ol>
                        <li> діти до 5 років включно</li>
                        <li> Особи віком 75 років і старші</li>
                        <li> Інваліди 1 групи + 1 супроводжуючий</li>
                    </ol>`
      },
      {
        title: "Хто має право на пільговий проїзд?",
        content: `<ol>
                        <li> Студенти</li>
                        <li> Пенсіонери</li>
                        <li> Інваліди 2-3 груп</li>
                        <li> Учасники АТО</li>
                    </ol>`
      }
    ],
    calendar: {
      title: "Календар поїздок",
      noItemsMessage:
        "Наразі немає активний поїздок, слідкуйте за оновленням на сайті та <a href='https://www.instagram.com/bus.pass/'>Instagram</a>. Розклад оновлюється щопонеділка.",
      descr:
        "Квитки обміну та поверненню не підлягають.\n" +
        "Онлайн продаж припиняється за 30хв до відправки.</br> Після чого квитки можна придбати безпосередньо в автобусі при посадці.",
      typeTickets: ["Дорослий квиток", "Дитячий квиток"],
      currency: "грн.",
      leftTickets: "Залишось місць на другому поверсі:"
    },
    formForPay: {
      title: "Заповніть форму",
      name: "Ім'я",
      surname: "Прізвище",
      telephone: "Номер телефону",
      errorForPhone:
        "Номер телефону повинен починатися з +, містити код країни та коректну кількість цифр",
      countSeats: ["Кількість дорослих місць", "Кількість дитячих місць"],
      agreeWithTerms: `Натискаючи "Оплатити" ви погоджуєтесь з <a href="./terms"> Договором оферти </a>`,
      childToolTip: "Від 5 до 12 років",
      countTickets: "Кількість квитків:",
      total: "Загальна сума",
      maxSeatsError: "Ви додали максимальну кількість вільних місць"
    },
    bookingInfo: {
      title: "Ви забронювали поїздку",
      dateTrip: "Дата поїздки",
      passenger: "Пасажир",
      telephone: "Контактний телефон",
      countSeats: ["Дорослих квитків", "Дитячих квитків"],
      place: " Зупинка знаходиться за адресою: вулиця Київська, 8, Вінниця",
      qr: "Для посадки пред’явіть QR код :",
      alreadyActivatedTicket: "Ваше бронювання вже активоване!"
    }
  },
  weekDays: {
    Sun: "Нд",
    Mon: "Пн",
    Tue: "Вт",
    Wed: "Ср",
    Thu: "Чт",
    Fri: "Пт",
    Sat: "Сб"
  },
  months: {
    Jan: "Січ",
    Feb: "Лют",
    Mar: "Бер",
    Apr: "Квіт",
    May: "Трав",
    Jun: "Черв",
    Jul: "Лип",
    Aug: "Серп",
    Sep: "Вер",
    Oct: "Жовт",
    Nov: "Лист",
    Dec: "Груд"
  }
};
