import * as Sentry from "@sentry/vue";

export function initSentry(app, router) {
  Sentry.init({
    app,
    dsn:
      "https://1965a4d183317b88d908a282e474b16c@o4507474877218816.ingest.de.sentry.io/4507474878660688",
    integrations: [Sentry.browserTracingIntegration({ router })]
  });
}
