<template>
  <div class="container mt-5 mb-5">
    <p style="text-align:center;">
      ПУБЛІЧНА ПРОПОЗИЦІЯ УКЛАСТИ ДОГОВІР (ОФЕРТА)
    </p>
    <p style="text-align:center;">
      на надання екскурсійних послуг шляхом придбання квитка
    </p>
    <p style="text-align:center;">(Публічна оферта BUSPASS city tour</p>
    <p style="text-align:center;">ФОП Яцковська Тетяна Володимирівна)</p>
    <p style="text-align:justify;">
      1. Визначення1. В цьому документі нижчеприведені терміни використовуються
      однаково в однині й множині (якщо інше не випливає зі змісту терміна і/або
      визначення) у наступних значеннях:&nbsp;
    </p>
    <p style="text-align:justify;">
      1.1. “Веб-сайт” — відкритий для вільного візуального ознайомлення,
      публічно доступний ресурс, що належить Виконавцю та розміщений в мережі
      Інтернет за адресою
      <a target="_blank" href="https://buspass.vn.ua/">
        <span style="color:rgb(0, 0, 0);">https://buspassua.vn.ua</span> </a
      >, за допомогою якого забезпечується відображення інформації про дату і
      час початку та закінчення екскурсії; ціну і порядок здійснення оплати
      тощо.
    </p>
    <p style="text-align:justify;">
      1.2. “Договір оферти” — це договір на туристичне обслуговування між
      Виконавцем і Туристом, який укладається шляхом акцепту оферти.
    </p>
    <p style="text-align:justify;">
      1.3. “Акцепт оферти” — повне і беззастережне прийняття умов оферти шляхом
      здійснення дій Туристом, що виражають намір скористатися веб-сайтом
      Виконавця&nbsp; для оформлення його послуг.
    </p>
    <p style="text-align:justify;">
      1.4. “Користувач” — дієздатна фізична особа, яка досягла 18 років, має
      законне право вступати в договірні відносини з Виконавцем.
    </p>
    <p style="text-align:justify;">
      1.5. “Виконавець” — ФОП Яцковська Тетяна Володимирівна, що діє на підставі
      Виписки з Єдиного державного реєстру юридичних осіб, фізичних
      осіб-підприємців та громадських формувань (номер запису в Єдиному
      державному реєстрі юридичних осіб та фізичних осіб-підприємців
      No2001740000000072313 від 09.04.2021), яка на законних підставах публікує
      ціни та умови проведення туристичного обслуговування на Веб-сайті,
      здійснює продаж квитків на Захід та є виконавцем Заходу.
    </p>
    <p style="text-align:justify;">
      1.6. "Ануляція" – видалення Квитків із Замовлення з автоматичним
      переведенням їх у вільний продаж у Системі. Замовлення, не оплачене в
      Період дії броні, анулюється автоматично по закінченні Періоду дії броні
      (якщо інше не передбачено додатковою інформацією на сайті). 1.7. "Квиток"
      – документ, сформований з використанням автоматизованої Системи Виконавця
      на бланку, що засвідчує право Покупця на відвідування Заходу та містить
      інформацію про найменування, дату, час, місце проведення, вартість послуги
      відвідування Заходу, а також додаткову інформацію правового або технічного
      характеру. 1.8. "Електронний квиток" – документ, сформований з
      використанням автоматизованої Системи Виконавця на бланку, що засвідчує
      право Покупця на відвідування Заходу, що містить штрих-код, номер
      замовлення, прізвище та ім'я Одержувача послуги, інформацію про
      найменування, дату, місце проведення Заходу, вартість послуги, а також
      додаткову інформацію правового або технічного характеру. Доступність
      послуги формування Електронного квитка регламентується Виконавцем і
      залежить від можливості використання в місці проведення Заходу
      автоматизованої системи контролю доступу на основі зчитування штрих-коду
      та/або можливості отримання Квитка безпосередньо в точці/точках продажу в
      місці проведення Заходу перед початком згідно з умовами цієї Оферти. 1.9.
      "Бронь" – сформоване Замовлення, що знаходиться в режимі очікування оплати
      Покупцем. 1.10. "Період дії броні" – період часу з моменту оформлення
      Замовлення до моменту вчинення оплати Замовлення, встановлений Виконавцем
      в Системі для кожного Заходу окремо, протягом якого Квитки, включені в
      Замовлення, недоступні для використання іншими особами, крім Покупця цього
      Замовлення протягом всього Періоду дії броні. 1.11. "Замовлення" – один
      або кілька Квитків на один Захід, обраних Покупцем із Системи та
      об'єднаних в Системі єдиним ідентифікаційним номером. 1.12. "Захід" –
      культурно-видовищний захід, екскурсія, а також будь-яка інша подія,
      відвідування якої можливо тільки після пред'явлення спеціального документа
      – Квитка та/або Електронного квитка. 1.13. "Покупець" – фізична або
      юридична особа, яка використовує послуги Виконавця з бронювання і
      оформлення Квитків на умовах дотримання цієї Оферти. 1.14. "Одержувач
      квитка" – фізична особа, уповноважена Покупцем отримати придбаний покупцем
      Квиток, а також та, що володіє правом провести обмін Електронного квитка
      на Квиток відповідно до умов цієї Оферти. 1.15. "Система" – автоматизована
      інформаційна система buspass.vn.ua – програмний комплекс, призначений для
      бронювання Квитків, що складається з web-порталу buspass.vn.ua і
      внутрішньоофісної системи автоматизації бізнес-процесів, що задовольняє
      вимогам щодо захисту від несанкціонованого доступу, ідентифікації,
      фіксування і збереження всіх операцій з Квитком (у тому числі Електронним
      квитком), а також забезпечує при заповненні бланка Квитка (у тому числі
      Електронного квитка) і випуску Квитка (у тому числі Електронного квитка)
      схоронність унікальних серії та номера квитка (у тому числі Електронного
      квитка).&nbsp;
    </p>
    <p style="text-align:justify;">
      1.16. “Штрих-код” – код сформований Системою який присвоюється Квитку та
      ідентифікує його.
    </p>
    <p style="text-align:justify;">
      1.17. “Номінальна вартість квитка” - вартість Квитка встановлена
      Виконавцем для всіх Покупців.
    </p>
    <p style="text-align:justify;">
      1.18. “Ціна Квитка” (замовлення) - сукупна сума Номінальної вартості
      Квитка та Супутніх послуг.
    </p>
    <p style="text-align:justify;">
      1.19. “Супутні послуги” - послуги, які нерозривно пов’язані з процедурою
      продажу Квитка за допомогою Системи, та які надаються Агентом та
      споживаються Покупцем безпосередньо під час продажу Квитка.
    </p>
    <p style="text-align:justify;">
      1.20. “Точка продажу” — каса з продажу Квитків як Агента так і Субагентів
      Агента.
    </p>
    <p style="text-align:justify;">
      1.21. “Правила перебування на Заході” - це норми й правила Виконавця,
      встановлені для Глядачів під час їх перебування на Заході.
    </p>
    <p style="text-align:justify;">
      1.22. “Персональні дані” - це відомості чи сукупність відомостей про
      фізичну особу, яка за їх допомогою ідентифікована або може бути конкретно
      ідентифікована (в тому числі але не виключно: прізвище, ім’я, стать,
      контактний номер телефону, місто).
    </p>
    <p style="text-align:justify;">
      2. ЗАГАЛЬНІ ПОЛОЖЕННЯ2.1. Ця Оферта регламентує умови і порядок продажу
      Квитків, а також права та обов'язки, що виникають у зв'язку з цим у
      Виконавця і Покупця. 2.2. Продаж Квитків Покупцеві, в тому числі
      Електронних квитків, проводиться виключно на умовах цієї Оферти і лише в
      разі повного і беззастережного прийняття умов цієї Оферти Покупцем
      (акцепту Оферти). Частковий акцепт, а також акцепт на інших умовах не
      допускається. 2.3. Якщо Покупець не приймає в повному обсязі умови цієї
      Оферти, продаж Квитків, в тому числі Електронних квитків, не проводиться.
      Придбані Покупцем Квитки не можуть бути повернуті, в тому числі в разі
      відмови Покупця на підставі незгоди Покупця з умовами цієї Оферти після
      акцепту її умов та оплати Квитка. Повернення коштів за придбані Покупцем
      Квитки можливе лише в порядку і на умовах, встановлених цією Офертою. 2.4.
      При придбанні Квитків у Виконавця юридичною особою оплата Квитків
      проводиться юридичною особою тільки за допомогою перерахування коштів на
      розрахунковий рахунок на підставі рахунку, що виставляється Виконавцем.
      2.5. Виконавець має право вносити зміни в справжню Оферту, але в
      будь-якому випадку такі зміни публікуються своєчасно і знаходяться в
      загальному доступі в мережі Інтернет та в точках продажу. Використання
      Покупцем послуг Виконавця після внесення змін до тексту Оферти означає
      акцепт Оферти з урахуванням внесених змін. 2.6. Умови цієї Оферти дійсні
      як для Покупців, що здійснюють оплату через інтернет-сайт, так і в точках
      продажу, і не залежать від форми оплати. 2.7. Виконавець має право
      розірвати Оферту в будь-який час без попереднього повідомлення Покупця у
      разі порушення останнім умов цієї Оферти та інших правил. 2.8. Ця Оферта
      набуває чинності з дати її розміщення на сайті Виконавця і діє
      безстроково.
    </p>
    <p style="text-align:justify;">
      3. ПРЕДМЕТ ОФЕРТИ3.1. Предметом цієї Оферти є надання Покупцеві послуг з
      бронювання, оформлення, продажу і доставки (останнє – за додаткову плату)
      Квитків на Заходи в порядку і на умовах, передбачених цією Офертою (далі
      по тексту – Послуги). 3.2. Виконавець не несе відповідальності за дії
      третіх осіб з продажу Квитків, що діють від свого імені.
    </p>
    <p style="text-align:justify;">
      3.3. Відповідно до положень Закону України « Про захист персональних
      даних» № 2297-VI від 01.06.2010 р, Покупець визнає і погоджується з
      наданням Виконавцю своїх персональних даних, а рівнозначно із цим і
      персональних даних третіх осіб, зазначених Покупцем, які отримані
      Виконавцем в процесі оформлення Квитка (Замовлення), а саме: прізвище,
      ім'я, стать, контактний номер телефону, місто, адреса доставки (у разі
      надання послуг з доставки Квитків) та адреса електронної пошти. Обробка
      персональних даних Покупця здійснюється відповідно до законодавства
      України. Покупець дає Виконавцю право на обробку його персональних даних у
      зв'язку з наданням Покупцю Послуг, обумовлених цією Офертою, в тому числі
      у цілях отримання Покупцем рекламних повідомлень про захід, квитки. У
      зв'язку з тим, що обробка персональних даних Покупця здійснюється у
      зв'язку з виконанням договору, укладеного на умовах цієї Оферти, згода
      Покупця на обробку його персональних даних не потрібна. Термін
      використання наданих персональних даних – безстроково. Покупець гарантує і
      несе відповідальність за те, що дані, зазначені в Замовленні, є
      добровільно наданими ними і третіми особами при оформленні Квитка, і всі
      ці особи ознайомлені й згодні з Офертою.
    </p>
    <p style="text-align:justify;">
      4. ПРАВИЛА АКЦЕПТУ4.1. Ця оферта вважається основним документом в
      офіційних взаєминах між Покупцем і Виконавцем з купівлі-продажу Квитків.
      Прийняття Покупцем цієї Оферти здійснюється шляхом послідовного вчинення
      Покупцем дій, зазначених у п. 4.3 цієї Оферти (акцепт Оферти). Вчинення
      акцепту Покупцем цієї Оферти проводиться добровільно і рівносильно
      укладенню письмового договору (п. 2 ст. 642 Цивільного кодексу України).
      4.2. До здійснення акцепту Оферти Покупець повинен бути впевнений, що всі
      умови цієї Оферти йому зрозумілі і він приймає їх безумовно і в повному
      обсязі. 4.3. Акцепт цієї Оферти Покупцем здійснюється шляхом послідовного
      вчинення таких дій: а) звернення до Точки продажу та/або звернення до
      Інтернет-сайту Виконавця; б) вибір конкретного Заходу та категорії
      Квитка/ів з наявних на момент звернення Покупця; в) ознайомлення з умовами
      цієї Оферти; г) ознайомлення з віковими обмеженнями конкретних Заходів, а
      також надання контактного номера телефону, адреси електронної пошти,
      прізвища та імені Одержувача Електронного квитка, а за умови оформлення
      доставки Квитка/ов – адреси та часу доставки. 4.4. Після здійснення всіх
      дій, зазначених у пункті 4.3 цієї Оферти, Покупець, в залежності від
      обраного способу придбання Квитка: Квитка або Електронного квитка,
      проводить оплату Замовлення у відповідності з обраним Покупцем способом
      придбання із відповідним такому способу придбання способом оплати в
      терміни, передбачені правилами оплати для конкретного Заходу. 4.5. Після
      вчинення оплати Замовлення Покупець або одержує Квиток самостійно в Точках
      продажів або шляхом оформлення його доставки (за окрему плату), або
      самостійно роздруковує Електронний квиток, або здійснює запис Електронного
      квитка в пам'ять технічного пристрою, що належить Покупцеві, за умови, що
      такий пристрій допускає можливість демонстрації документа для контролю
      (сканування) системою контролю доступу в місці проведення Заходу. 4.5.1.
      При замовленні Квитка за допомогою Інтернет-сайту Покупцеві повідомляється
      (або направляється sms-повідомлення на контактний номер телефону та/або
      електронну поштову адресу) ідентифікаційний номер Замовлення, який
      Покупець зобов'язаний повідомити (іноді разом зі своїми прізвищем та
      ім'ям, якщо подібне є) при отриманні квитка в Точці продажів або при
      отриманні Квитка при його доставці. При зверненні в Точку продажів
      отримання Квитка проводиться на місці або здійснюється його доставка за
      вказаною Покупцем адресою.&nbsp;
    </p>
    <p style="text-align:justify;">
      4.5.2. Відповідно до положень пункту 4.5 цієї Оферти Покупець може
      отримати Електронний квиток за допомогою його відправки Агентом на вказану
      Покупцем адресу електронної пошти. Відправлення Електронного квитка
      здійснюється лише за умови виконання Покупцем зобов'язання по повній
      оплаті вартості Електронного квитка.&nbsp;
    </p>
    <p style="text-align:justify;">
      5. ПОРЯДОК ЗДІЙСНЕННЯ ПЛАТЕЖУ5.1. Покупець має право здійснити оплату
      Квитків будь-яким із способів, запропонованих Виконавцем, інформація про
      які знаходиться на сайті Виконавця або повідомляється в Контактному центрі
      Виконавця. 5.2. Платіж визнається вчиненим Покупцем в момент підтвердження
      успішної транзакції з боку банку або платіжної системи або в момент
      надходження грошових коштів до каси Точки продажу. 5.3. У разі оплати
      Замовлення банківською картою на web-сайті Виконавця Покупець зобов'язаний
      використовувати банківську карту, випущену на ім'я Покупця. Одночасно з
      цим касир в Точці продажу квитків має право вимагати надання Покупцем
      оригіналів документів, що засвідчують особу Покупця. 5.4. Електронний
      квиток може бути отриманий Покупцем тільки за умови формування Замовлення
      самостійно Покупцем безпосередньо на Інтернет-сайті Виконавця та оплати
      замовлення тільки за допомогою банківської карти або іншим безготівковим
      способом, інформація про який наведена на Інтернет-сайті Виконавця. 5.5.
      Після оплати Замовлення згідно п. 5.2 цієї Оферти Замовлення вважається
      проданим і у Покупця виникає право відвідати Захід. 5.6. При несплаті або
      частковій оплаті замовлення протягом Періоду дії броні Замовлення
      вважається непроданим і анулюється.
    </p>
    <p style="text-align:justify;">
      6. ПОВЕРНЕННЯ КОШТІВ6.1. Грошові кошти, що надійшли Виконавцю в якості
      оплати Квитка/Електронного квитка, підлягають поверненню Покупцеві тільки
      у разі відміни Заходу.&nbsp;
    </p>
    <p style="text-align:justify;">
      6.2. Повернення грошових коштів відповідно до умов цієї Оферти за придбані
      Покупцем Квитки здійснюється тільки за Квитки, придбані в Точках продажів
      або на Інтернет-сайті Виконавця.
    </p>
    <p style="text-align:justify;">
      &nbsp;6.3.&nbsp; Повернення грошових коштів проводиться в Точці продажу в
      робочий час або шляхом перерахування на платіжну картку з якої була
      здійснена оплата таких квитків, з обов'язковим пред'явленням самого квитка
      та документа, що посвідчує особу (паспорта, військового квитка,
      студентського квитка, посвідчення водія, службового посвідчення), а також
      ІПН.6.4. Грошові кошти повертаються Покупцеві тільки при обов'язковому
      фактичному поверненні Покупцем придбаного квитка. 6.5. Повернення грошових
      коштів відповідно до умов цієї Оферти за придбані Покупцем Квитки завжди
      проводиться за вирахуванням вартості доставки замовлених і оплачених
      Покупцем Квитка/ів (якщо доставка була замовлена й оплачена Покупцем).
      6.6. Виконавець не здійснює повернення грошових коштів за загублені,
      пошкоджені Квитки, а також Квитки з будь-якими виправленнями і прихованою
      будь-яким штучним способом інформацією. 6.7. У випадку застосування
      Покупцем Промо-кодів, знижок и т.п., до повернення підлягає сума із
      врахуванням наданих знижок.
    </p>
    <p style="text-align:justify;">7. ОТРИМАННЯ КВИТКІВ ПОКУПЦЕМ</p>
    <p style="text-align:justify;">
      7.1. Передача Квитків Покупцеві здійснюється виключно після повної оплати
      Ціни Квитка, Супутніх послуг на вибір Покупця, одним із наступних
      способів:
    </p>
    <p style="text-align:justify;">
      - повідомленням на мобільний телефон Покупця або на електронну пошту
      Покупця Виконавцем надсилається лист, як додаток в листі знаходиться бланк
      Електронного квитка з унікальним Штрих-кодом.
    </p>
    <p style="text-align:justify;">
      - Покупець може самостійно отримати Квиток безпосередньо в Точці продажу.
    </p>
    <p style="text-align:justify;">
      7.2. При одержанні Квитка в паперовій формі Покупцем/Одержувачем квитка в
      Точці продажу, він зобов’язаний ідентифікувати себе, тобто назвати себе й
      пред’явити документ, що підтверджує його особу, або назвати номер
      Замовлення.
    </p>
    <p style="text-align:justify;">
      8. ОКРЕМІ ПОЛОЖЕННЯ ЩОДО ЕЛЕКТРОННИХ КВИТКІВ
    </p>
    <p style="text-align:justify;">
      8.1. При придбанні Електронного квитка Покупець в будь-якому випадку
      зобов’язується повідомити свою дійсну адресу електронної пошти, телефон, а
      також вказати свої прізвище та ім’я одержувача Квитка. В разі ненадання
      такої інформації (дійсних адреси електронної пошти, телефону, прізвища та
      імені Покупця і одержувача Квитка, за потреби паспортних даних), а так
      само у разі надання неправдивої або недійсної інформації, Виконавець
      вправі відмовити Покупцеві в придбанні Електронного квитка.
    </p>
    <p style="text-align:justify;">
      8.1.1. В разі якщо Покупець під час купівлі Квитка повідомляє (вносить)
      недостовірну інформацію про себе, то в даному випадку Виконавець не несе
      жодної відповідальності за якість та своєчасність надання екскурсійних та
      Супутніх послуг.
    </p>
    <p style="text-align:justify;">
      8.2. Покупець приймає та погоджується з тим, що він повною мірою несе
      відповідальність за повідомлення помилкової або недійсної електронної
      поштової адреси.
    </p>
    <p style="text-align:justify;">
      8.3. Електронний квиток надсилається Покупцеві на його електронну поштову
      адресу лише за умови дотримання Покупцем умов цієї Оферти про оплату
      вартості Квитка та Супутніх послуг.
    </p>
    <p style="text-align:justify;">
      8.4. Покупець зобов’язаний самостійно та за свій рахунок здійснити друк
      Електронного квитка. При здійсненні друку Електронного квитка Покупець
      повинен переконатися в належній якості друку Електронного квитка
      (читабельність всіх деталей). В разі невиконання або неналежного виконання
      обов’язку, встановленого цим пунктом, Покупець повною мірою несе
      відповідальність за подібні дії (бездіяльність), в тому числі в разі
      відмови Виконавцем в проході на Захід в зв’язку з неналежним друком
      Електронного квитка. Виконавець не несе жодної відповідальності за
      будь-які збитки, що виникають у Покупця, у разі порушення умов цього
      пункту.
    </p>
    <p style="text-align:justify;">
      8.5. Покупець має право, якщо подібне допускається Виконавцем Заходу і на
      це вказано Виконавцем в момент оформлення Електронного квитка на Сайті,
      при проході на Захід пред’явити Електронний квиток, що зберігається в
      пам’яті технічного пристрою, що належить Покупцеві, за умови, що такий
      пристрій допускає можливість демонстрації документа для контролю
      (сканування) системою контролю доступу в місці проведення Заходу.
    </p>
    <p style="text-align:justify;">
      8.6. Покупець самостійно несе відповідальність за збереження та захист
      Штрих-коду Електронного квитка від копіювання. У разі копіювання (втрати,
      тощо) Штрих-коду доступ на Захід буде відкритий лише один раз по тому
      Квитку, який був пред’явлений до сканування першим (дані умови також
      поширюються на друковані квитки).
    </p>
    <p style="text-align:justify;">
      8.6.1. Покупець погоджується з тим, що в разі якщо він не зумів зберегти
      Штрих-код від копіювання, в цьому випадку Виконавець не несе жодної
      відповідальності за відвідування Заходу особою яка першою повідомила
      Штрих-код та не є Покупцем або Одержувачем квитка.
    </p>
    <p style="text-align:justify;">
      9. ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН9.1. Покупець має право: 9.1.1. Самостійно з
      відкритих джерел наводити довідки про ціну Квитків на Заходи та сервісних
      послугах інших офіційних квиткових операторів при ухваленні рішення про
      придбання Квитків у Виконавця.9.1.2. Отримувати інформацію про правила
      продажу Квитків, наявних категоріях Квитків та іншу інформації щодо
      Заходів, якою володіє Виконавець в рамках своїх повноважень, з
      використанням мережі Інтернет, дзвінка до Контактного центру та іншими
      способами, зазначеними на Інтернет-сайті. При цьому Виконавець має право
      без пояснення причин відмовити Покупцеві в бронюванні та/або продажу
      Квитків. 9.1.3. Вибирати спосіб оплати Квитка із запропонованих Виконавцем
      на сайті та/або Точках продажів. При цьому Покупець зобов'язаний
      самостійно ознайомитися з правилами користування тим чи іншим способом
      платежу. 9.1.4. Направляти відгуки про роботу Виконавця за контактами,
      вказаними на сайті. 9.1.6. Покупець має право відмовитися від придбання
      Квитка у Виконавця до моменту вчинення оплати Замовлення відповідно до
      умов, що передбачені цією Офертою. Така відмова потягне за собою
      припинення перед Покупцем всіх обов'язків Виконавця9.2. Покупець
      зобов'язаний: 9.2.1. Детально ознайомитися з усіма правилами покупки
      Квитків та умовами цієї Оферти й прийняти їх при здійсненні покупки
      Квитка, а також всі додаткові правила, що регулюють відносини Сторін
      відповідно до цієї Оферти. 9.2.2. При оформленні Замовлення необхідно
      надати вірну, точну і повну інформацію про себе. Покупець визнає, що він
      несе повну відповідальність за дані, що повідомляються Виконавцю. Покупець
      визнає, що не має жодних претензій до Виконавця за некоректно оформлене
      Покупцем з використанням Інтернет-сайту Виконавця Замовлення, так як сам
      не переконався в його коректності під час вчинення оформлення. 9.2.3.
      Оплатити у повному обсязі вартість оформленого Замовлення. 9.2.4. У разі
      придбання Квитка за допомогою Інтернет-сайту Виконавця за умови оплати
      шляхом безготівкового перерахування грошових коштів за Замовлення без
      оформлення послуг з доставки, звернутися в Точки продажі Виконавця не
      пізніше дати проведення Заходу для отримання Квитка. В момент звернення
      щодо отримання Квитка повідомити ідентифікаційний номер Замовлення, а
      також пред'явити документ, що засвідчує особу Покупця або Одержувача
      Квитка. Пред'явлення документа необхідно для виключення можливих
      шахрайських операцій. У разі, якщо Покупець або Отримувач квитка не вчинив
      своєчасно обмін Електронного квитка на Квиток з причин, не залежних від
      Виконавця, Виконавець визнається тим, що належно виконав свої
      зобов'язання, передбачені цією Офертою. Покупець не має права пред'являти
      претензії до Виконавця у разі неотримання Квитка до дати проведення Заходу
      з причин, не залежних від Виконавця. 9.2.5 Дотримуватись встановлених
      Організатором правил відвідування Заходу.
    </p>
    <p style="text-align:justify;">
      9.2.6 З метою запобігання можливості використання придбаного Квитка
      сторонньою особою, Покупець/Одержувач квитка зобов’язані прикладати всіх
      залежних від них зусиль, завдяки яким такий Квиток або нанесена на ньому
      інформація, не могли б потрапити третім особам, в тому числі не робити
      копії Квитка та в жодному разі не розголошувати Штрих-кодів Квитка.
    </p>
    <p style="text-align:justify;">
      9.3. Виконавець має право: 9.3.1. Вимагати від Покупця дотримання всієї
      процедури оформлення/оплати Замовлення за правилами, викладеними в цій
      Оферті. 9.3.2. При відмові Покупця прийняти справжню Оферту на викладених
      у ній умовах відмовити Покупцеві в продажі йому Квитка і наданні Послуг.
      9.3.3. Будь-коли виробляти модифікацію будь-якого програмного забезпечення
      Системи Виконавця, в тому числі Інтернет-сайту, призупиняти роботу
      програмних засобів, що забезпечують функціонування сайту, при виявленні
      істотних несправностей, помилок і збоїв, а також в цілях проведення
      профілактичних робіт і запобігання випадків несанкціонованого доступу до
      сайту. 9.3.6. Вимагати від Покупця повної оплати Квитка або Електронного
      квитка перед тим, як справити продажу Квитка. 9.3.7. Анулювати оформлене
      Замовлення, якщо воно не буде оплачене в Період дії броні. Анульоване
      Замовлення не може бути відновлено для повторної оплати, окрім як шляхом
      створення Покупцем нового Замовлення. 9.3.8. При недотриманні Покупцем
      будь-якого з пунктів Оферти відмовити йому в подальшому наданні
      послуг.&nbsp;
    </p>
    <p style="text-align:justify;">
      9.3.9 Змінювати ціни на не замовлені (не зарезервовані) й не оплачені
      Квитки.9.4. Виконавець зобов'язаний: 9.4.1. Надавати Покупцю:- необхідну
      інформацію про конкретні заходи;- необхідну інформацію про дати, місце і
      час повернення коштів за придбані Квитки в разі скасування Заходу.
      Вичерпною інформацією вважається та інформація, яку Виконавець публікує на
      сайті та надає до Точки продажу та кас Виконавця.9.4.2. Не використовувати
      персональні дані Покупця для несанкціонованої розсилки матеріалів, що не
      відносяться до оформлення Замовлення.
    </p>
    <p style="text-align:justify;">
      9.4.3 Виконавець зобов’язується забезпечити пропуск Одержуваа квитка, який
      пред’явить на вході дійсний Квиток на Захід для одержання ним екскурсійної
      послуги, за умови дотримання ним Правил перебування на Заході. Дійсним
      вважається Квиток, який був придбаний Покупцем у Виконавця, за умови, що
      він не буде використаний (погашений) ким-небудь до проходження Одержувачем
      квитка контролю, або якщо його не буде анульовано та повернуто у вільний
      продаж.
    </p>
    <p style="text-align:justify;">
      10. ВІДПОВІДАЛЬНІСТЬ10.1. У разі невиконання або неналежного виконання
      своїх зобов'язань, передбачених цією Офертою, Сторони несуть
      відповідальність відповідно до законодавства України та умовами цієї
      Оферти. 10.2. Виконавець не несе відповідальності за невідповідність
      наданих послуг очікуванням Покупця та/або його суб'єктивній оцінці. Поради
      та рекомендації, надані Покупцю, не можуть розглядатися як гарантії. 10.3.
      Покупець приймає на себе всі можливі комерційні ризики, пов'язані з його
      діями по допущенню помилок і неточностей у наданих ним своїх особистих
      даних. 10.4. Виконавець не несе відповідальності за будь-які збитки та
      моральну шкоду, понесені Покупцем в результаті помилкового розуміння або
      нерозуміння ним інформації про порядок оформлення/оплати Замовлення, а
      також отримання і використання Послуг.&nbsp;
    </p>
    <p style="text-align:justify;">
      10.5. Виконавець не несе відповідальності перед Покупцем/Одержувачем
      квитка, не повертає сплачену вартість туристичних послуг й компенсацію за
      моральний збиток якщо та у випадку:
    </p>
    <p style="text-align:justify;">
      10.5.1. несвоєчасної, неповної чи неправильної оплати послуг;
    </p>
    <p style="text-align:justify;">
      10.5.2. несвоєчасного внесення змін чи ануляції замовлення Покупцем;
    </p>
    <p style="text-align:justify;">
      10.5.3. за неявку чи запізнення Покупця/Одержувача квитка (осіб, що з ним
      подорожують) на встановлені місця збору та відправлення протягом Заходу;
    </p>
    <p style="text-align:justify;">
      10.5.4. за недотримання Покупця/Одержувача квитка (особами, що з ним
      подорожують) встановлених Перевізником правил поведінки.
    </p>
    <p style="text-align:justify;">
      10.5.5. неможливість Покупця/Одержувача квитка (осіб, що з ним
      подорожують) скористатися замовленими послугами із причин порушення
      правопорядку, заподіяння шкоди (занепокоєння) навколишньому середовищу,
      оточуючим, в тому числі в результаті перебування Покупця/Одержувача квитка
      (осіб, що з ним подорожують) в стані алкогольного чи наркотичного
      сп'яніння або порушення інших правил поведінки в громадських місцях,
      проїзду або провозу багажу;
    </p>
    <p style="text-align:justify;">
      10.5.6.&nbsp; невикористання Покупця/Одержувача квитка (особами, що з ним
      подорожують) послуг з власної ініціативи чи вини, в тому числі з причини
      хвороби.
    </p>
    <p style="text-align:justify;">
      10.5.7. неявку Покупця/Одержувача квитка у вказаний час на місце збору. В
      такому разі Виконавець залишає за собою право продовжити виконання
      програми Заходу та не чекати Покупця/Одержувача квиткаа. В такому разі
      Покупця/Одержувача квитка буде змушений самостійно наздоганяти групу або
      повертатись додому за власний рахунок.
    </p>
    <p style="text-align:justify;">
      10.6. Сторони звільняються від відповідальності за повне або часткове
      невиконання своїх зобов'язань за цією Офертою, якщо таке невиконання стало
      наслідком обставин непереборної сили, тобто надзвичайних і невідворотних
      за даних умов обставин. До обставин непереборної сили, зокрема,
      відносяться: епідемії, стихійні лиха, військові дії, страйки, стихійні
      безлади і протести, дії та рішення державних органів влади, введення
      надзвичайного стану, введення військового стану, збої, що виникають в
      телекомунікаційних і енергетичних мережах. 10.7. Виконавець не несе
      відповідальності за працездатність і/або захищеність інформаційних каналів
      зв'язку, що використовуються Покупцем для звернення до Інтернет-сайту
      Агента, а також за збереження інформації, в тому числі Електронного
      квитка, отриманого Покупцем відповідно до умов цієї Оферти. 10.8. Всі
      претензії або позови, пов'язані з цією Офертою, повинні бути заявлені в
      письмовому вигляді протягом 10 (десяти) календарних днів з дати їх
      виникнення. Після закінчення зазначеного терміну претензії розглядатися
      Виконавцем не будуть.
    </p>
    <p style="text-align:justify;">11. ПРОЦЕДУРА РОЗІРВАННЯ ДОГОВОРУ</p>
    <p style="text-align:justify;">
      11.1. Замовлення, в частині укладення Договору на надання екскурсійної
      послуги , а також Договору на надання Супутніх послуг , вважається не
      здійсненим, і не вимагає додаткових дій з боку Покупця й Виконавця, по
      його анулюванню (припиненню, розірванню), якщо: Покупець не підтвердив
      Виконавцеві свою згоду придбати обраний ним Квиток, або Виконавець не
      підтвердив свою згоду продати Квиток Покупцеві;
    </p>
    <p style="text-align:justify;">
      11.2. Замовлення, вважається анульованим з ініціативи Покупця, а
      зобов’язання сторін по укладеному між Виконавцем та Покупцем Договору на
      надання екскурсійноі послуги припиненими, якщо: 11.2.1. Покупець не
      оплатив Ціну Квитка протягом двадцяти (10) хвилин після підтвердження
      Виконавцем можливості придбання Квитка, якщо замовлення здійснювалося
      Покупцем через Сайт, або якщо Покупець не оплатив Ціну Квитка протягом
      однієї (1) хвилини, після підтвердження касирові в Точці продажу своєї
      згоди придбати обраний Квиток;11.2.2. Глядач не прибув в точку збору у
      запланований час початку Заходу та/або порушив Правила перебування на
      Заході, порушував та/або не дотримувався актів (рішень) органів державної
      влади України направлених на запобігання поширення на території України
      гострої респіраторної хвороби COVID-19, в тому числі але не виключно
      Постанову Кабінету Міністрів «Про встановлення карантину та запровадження
      обмежувальних протиепідемічних заходів з метою запобігання поширенню на
      території України гострої респіраторної хвороби COVID-19, спричиненої
      коронавірусом SARS-CoV-2» від 9 грудня 2020 р. № 1236
      (https://cutt.ly/gEYAiTI), у зв’язку з чим, не був допущений на Захід, або
      був виведений з автобусу силами співробітників Виконавця до завершення
      Заходу, та/або втратив Квиток, і, через це не зміг одержати екскурсійну
      послугу повністю або частково. У цьому випадку сплачені Покупцем Ціна
      Квитка поверненню не підлягають.
    </p>
    <p style="text-align:justify;">
      12. ВИРІШЕННЯ СУПЕРЕЧОК12.1. Суперечки, що виникають при виконанні
      договору на умовах цієї Оферти, вирішуються з обов'язковим дотриманням
      претензійного порядку. Претензія подається Виконавцю в письмовому вигляді
      з додатком документів, які обгрунтовують пропоновані вимоги, в строк не
      більше 10 (десяти) календарних днів з дати виникнення причини суперечки.
      Претензія, що надійшла, розглядається Виконавцем у термін не більше 10
      (десяти) календарних днів. 12.2. При неможливості досягнення згоди,
      суперечки, що виникли, підлягають розгляду в порядку, передбаченому чинним
      законодавством України. 12.3. З усіх інших питань, не передбачених цією
      Офертою, Сторони керуються чинним законодавством України.
    </p>
    <p style="text-align:justify;">
      13. СРОК ДІЇ ОФЕРТИ13.1. Справжня Оферта набуває чинності з дати її
      розміщення на сайті Виконавця і діє безстроково. Положення цього пункту
      поширюються і на доповнення (зміни) до справжньої Оферти. 13.2. Виконавець
      має право змінювати умови справжньої Оферти без додаткового інформування
      Покупця.
    </p>
    <p>14. КОНТАКТИ ВИКОНАВЦЯ</p>
    <p>тел. +380661690379</p>
    <p>
      пошта:
      <a target="_blank" href="mailto:buspassua@gmail.com">
        <span style="color:rgb(17, 85, 204);">buspassua@gmail.com</span>
      </a>
    </p>
  </div></template
>

<script>
export default {
  name: "Terms"
};
</script>

<style scoped></style>
