export function getHoursWithZero(h) {
  return h < 10 ? "0" + h : h;
}
export function getMinutesWithZero(m) {
  return m < 10 ? "0" + m : m;
}

export function getDateAndTime(dateString) {
  const d = new Date(dateString);

  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const monthNumber = d.getMonth() + 1;
  const month = monthNumber < 10 ? "0" + monthNumber : monthNumber;
  const hour = getHoursWithZero(d.getHours());
  const minutes = getMinutesWithZero(d.getMinutes());

  return day + "/" + month + "/" + d.getFullYear() + " " + hour + ":" + minutes;
}
