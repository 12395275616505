<template>
  <section id="contact" class="section">
    <div class="contact-form">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-xs-12">
            <div class="contact-us">
              <h3>{{ $t("footer.title") }}</h3>
              <div class="contact-address">
                <p>{{ $t("footer.schedule") }}</p>
                <p>{{ $t("footer.city") }}</p>
                <p class="phone wow bounceInUp">
                  Phone:
                  <a href="tel:+380661690379"><span>+38 066 169 03 79</span></a>
                </p>
                <p class="email">
                  E-mail:
                  <a href="mailto:buspassua@gmail.com"
                    ><span>buspassua@gmail.com</span></a
                  >
                </p>
                <p>
                  <a href="./terms" target="_blank">ДОГОВІР оферти</a>
                </p>
              </div>
              <div class="social-icons">
                <ul>
                  <li class="facebook">
                    <a
                      href="https://www.facebook.com/profile.php?id=100067489424052"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li class="instagram ">
                    <a href="https://www.instagram.com/bus.pass/"
                      ><i class="fa fa-instagram"></i
                    ></a>
                  </li>
                  <li class="telegram">
                    <a href="https://t.me/buspassvn"
                      ><i class="fa fa-telegram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-xs-12 d-none">
            <div class="contact-block">
              Place for owner
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact-Us"
};
</script>

<style scoped></style>
