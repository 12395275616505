<template>
  <!-- Navbar End -->

  <section id="hero-area" class="section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-10">
          <div class="contents text-center">
            <h1
              class="wow fadeInDown"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              {{ $t("home.intro.title") }}
            </h1>
            <p
              class="lead  wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="400ms"
              v-html="$t('home.intro.description')"
            ></p>
            <router-link
              class="btn btn-common wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="400ms"
              to="/buy-ticket"
              >{{ $t("buttons.buy") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Services Section Start -->
  <section id="services" class="section">
    <div class="container">
      <div class="section-header">
        <h2
          class="section-title wow fadeIn"
          data-wow-duration="1000ms"
          data-wow-delay="0.3s"
        >
          {{ $t("home.programs.title") }}
        </h2>
        <hr class="lines wow zoomIn" data-wow-delay="0.3s" />
        <!--        <p
          class="section-subtitle wow fadeIn"
          data-wow-duration="1000ms"
          data-wow-delay="0.3s"
        >
          В сезоні 2023 раді запропонувати вам такі тури:
        </p>-->
        <p
          class="section-subtitle wow fadeIn"
          data-wow-duration="1000ms"
          data-wow-delay="0.3s"
        >
          {{ $t("home.programs.description") }}
        </p>
      </div>
      <div class="row">
        <!--        <div
          class="col-md-4 col-sm-6"
          v-for="tripType of tripTypes"
          :key="tripType.id"
        >
          <TripType
            v-bind="tripType"
            :title="tripType.name"
            :title-en="tripType.nameEn"
          />
        </div>-->

        <div class="col-md-4 col-sm-6">
          <div class="item-boxes wow fadeInDown" data-wow-delay="0.2s">
            <div class="icon">
              <i class="lnr lnr-bus"></i>
            </div>
            <h4>Оглядова екскурсія Вінницею</h4>
            <p>
              Класична оглядова екскурсія - ідеальне знайомство з містом в
              стислий термін.
              <br />
              А якщо ви вже з ним добре знайомі? Тоді ми здивуємо вас! Адже на
              нашій екскурсії ви дізнаєтесь те, про що навіть не здогадувались.
              <br />
              Подорож в супроводі драйвового гіда-професіонала розкаже і покаже
              все найцікавіше, що вам потрібно знати про Вінницю.
              <br />
              Багатопланова екскурсія довжиною в 22км під відкритим небом на
              висоті 4х метрів - це вибір для тих, хто цінує свій час, комфорт
              та бажає отримати максимум.
              <br />
              Створено з любовʼю, відпрацьовано з натхненням!
              <br />
              Квиток 350грн (від 5 до 12років 250грн)
              <br />
              До 5ти років безкоштовно на руках у батьків.
              <br />
              Тривалість півтори години.
            </p>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 ">
          <div class="item-boxes wow fadeInDown" data-wow-delay="0.8s">
            <div class="icon">
              <i class="lnr lnr-star"></i>
            </div>
            <h4>БасПасіки в місті Вінниця</h4>
            <p>
              Бажаєте провести пізнавально вихідний з дітьми? Тоді ця програма
              саме для вас!
              <br />
              Оглядова екскурсія про історію міста від заснування до наших днів,
              але в легкій подачі, зробить розповідь цікавою для найменших.
              <br />
              А в кінці поїздки усі мандрівники з дитячим квитком отримають в
              подарунок інтерактивну карту-гру, розроблену спеціально для цієї
              програми! З нею ви зможете вдома закріпити почуту інформацію та
              пограти в гру-ходилку вже по відомому маршруту Вінницею.
              <br />
              Обираєте екскурсію для діток - ця підійде ідеально!
              <br />
              Квиток 350грн (від 5 до 12років 250грн)
              <br />
              До 5ти років безкоштовно на руках у батьків.
              <br />
              Тривалість півтори години.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6" style="">
          <div class="item-boxes wow fadeInDown" data-wow-delay="1.2s">
            <div class="icon"><i class="lnr lnr-music-note"></i></div>
            <h4>«НеВинні розмови»</h4>
            <p>
              Деякі науковці твердять, що правильніше називати наше місто не
              Вінницею, а Винницею, пов'язуючи походження назви міста з
              винокурнями або винницями. Ми не впевнені в правдивості цієї
              версії, але це є чудовим приводом для того, щоб зробити екскурсію
              про дивовижний світ вина.
              <br />
              Ми розкажемо про найстаріше вино в світі і про найдорожче, про
              криваві вінтажі та вино поза законом, і навіть про крижане вино!
              <br />
              Після екскурсії кожен з вас стане трішки сомельє. Ви дізнаєтесь
              тонкощі мистецтва виготовлення вина традиційними а також
              унікальними методами.
              <br />
              І, звичайно, на вас чекає дегустація! Три різновиди вина з
              історією в прохолодний літній вечір - що може бути краще?!
              <br />
              Чекаємо старих і нових друзів.
              <br />Квиток 450грн <br />Тривалість 1год. 45хв.
              <br />
              !Тільки для повнолітніх
              <br />
              Це перша екскурсія, в якій ми ризикуємо поїхати на друге коло.
              <br />
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="item-boxes wow fadeInDown" data-wow-delay="1.2s">
            <div class="icon"><i class="lnr lnr-music-note"></i></div>
            <h4>«Вечір саксофону»</h4>
            <p>
              Тільки уяви, як ти плавно їдеш на висоті 4 метри під відкритим
              небом вечірніми вуличками міста і слухаєш живу гру на саксофоні!
              <br />
              А потім тебе пригощають келихом холодного ігристого і везуть на
              романтичний захід сонця.
              <br />
              Такий вечір запам’ятається на все життя.
              <br />Тут не буде багато розмов, лише музика, релакс та приємна
              компанія.
              <br />
              Тож, якщо бажаєш відпочити, відновити сили та зарядитись енергією
              - ця музична програма творена для цього.
              <br />
              <br />
              Тривалість 90хв
              <br />
              Вартість квитка 480грн
            </p>
          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="item-boxes wow fadeInDown" data-wow-delay="0.8s">
            <div class="icon">
              <i class="lnr lnr-history"></i>
            </div>
            <h4>Урок історії</h4>
            <p>
              Наша компанія відкрита для співпраці з закладами освіти. Ми з
              радістю організуємо захоплюючу та пізнавальну екскурсію, яка стане
              незабутнім уроком історії для учнів усіх класів та надихне на
              подальше здобуття знань.
              <br />
              Наш гід підготує програму, розраховану на вікову категорію учнів.
              Маршрут адаптується під вашу відправну та кінцеву точки. У вашому
              розпорядженні 50 місця на другому поверсі та ще 15 на першому, що
              надасть можливіть розмістити увесь клас та навіть більше!
              <br />
              Орієнтовна тривалість півтори-дві години.
              <br />
              Для замовлення та уточнення деталей телефонуйте
              <a href="tel:+380661690379">0661690379</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Services Section End -->

  <!-- Map Section Start -->
  <div class="section" style="background: #f2cb3f;" id="route">
    <div class="section-header">
      <h2 class="section-title">{{ $t("home.map.title") }}</h2>
      <hr class="lines" />
    </div>
    <div class="row justify-content-center">
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1Q1LcydOJC5PuT0xWdMgHNq1jRL08BWRf"
        height="600"
        frameborder="0"
        style="border:0"
        class="col-9"
      ></iframe>
    </div>
  </div>
  <!-- Map Section End -->

  <!-- Features Section Start -->
  <section id="features" class="section" data-stellar-background-ratio="0.2">
    <div class="container">
      <div class="section-header">
        <h2 class="section-title">{{ $t("home.info.currentQuestions") }}</h2>
        <hr class="lines" />
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-12 col-xs-12">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                <span class="icon">
                  <i class="lnr lnr-bus"></i>
                </span>
                <div class="text">
                  <h4>{{ $t("home.info.whereStart") }}</h4>
                  <p>{{ $t("home.info.startTrip") }}</p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                <span class="icon">
                  <i class="lnr lnr-link"></i>
                </span>
                <div class="text">
                  <h4>{{ $t("home.info.wherePay") }}</h4>
                  <p>
                    {{ $t("home.info.payWith") }}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                <span class="icon">
                  <i class="lnr lnr-star"></i>
                </span>
                <div class="text">
                  <h4>{{ $t("home.info.accessTrip") }}</h4>
                  <p>
                    {{ $t("home.info.accessTripAnswer") }}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                <span class="icon">
                  <i class="lnr lnr-clock"></i>
                </span>
                <div class="text">
                  <h4>{{ $t("home.info.currentSchedule") }}</h4>
                  <p v-html="$t('home.info.currentScheduleAnswer')"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xs-12">
          <div class="show-box">
            <img
              class="img-fulid"
              src="img/features/feature.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  <section id="features" class="section" data-stellar-background-ratio="0.2">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">{{$t('home.features.title')}}</h2>
                <hr class="lines">
                <p class="section-subtitle" v-html="$t('home.features.sub_title')"></p>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-12 col-xs-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                    <span class="icon">
                      <i class="lnr lnr-bus"></i>
                    </span>
                                <div class="text">
                                    <h4>{{$t('home.features.items.0.title')}}</h4>
                                    <p>{{$t('home.features.items.0.description')}}</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                    <span class="icon">
                      <i class="lnr lnr-link"></i>
                    </span>
                                <div class="text">
                                    <h4>{{$t('home.features.items.1.title')}}</h4>
                                    <p>{{$t('home.features.items.1.description')}}</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                    <span class="icon">
                      <i class="lnr lnr-clock"></i>
                    </span>
                                <div class="text">
                                    <h4>{{$t('home.features.items.2.title')}}</h4>
                                    <p>{{$t('home.features.items.2.description')}}</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                    <span class="icon">
                      <i class="lnr lnr-star"></i>
                    </span>
                                <div class="text">
                                    <h4>{{$t('home.features.items.3.title')}}</h4>
                                    <p>{{$t('home.features.items.3.description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="show-box">
                        <img class="img-fulid" src="img/features/feature.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section> -->
  <!-- Features Section End -->

  <!-- Portfolio Section -->
  <section id="portfolios" class="section">
    <!-- Container Starts -->
    <div class="container">
      <div class="section-header">
        <h2 class="section-title">{{ $t("home.portfolios.title") }}</h2>
        <hr class="lines" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <Slider :slides="sliderImages" v-if="isPortfolioSliderVisible" />
        </div>
      </div>
    </div>
    <!-- Container Ends -->
  </section>
  <!-- Portfolio Section Ends -->

  <!-- Start Video promo Section -->
  <section class="video-promo section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="video-promo-content text-center">
            <h2
              class="wow zoomIn"
              data-wow-duration="1000ms"
              data-wow-delay="100ms"
            >
              BusPass city tour
            </h2>
            <!--                        <p class="wow zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">Aliquam vestibulum
                            cursus felis. In iaculis iaculis sapien ac condimentum. Vestibulum congue posuere lacus, id
                            tincidunt nisi porta sit amet. Suspendisse et sapien varius, pellentesque dui non, semper
                            orci.</p>-->
            <a
              href="https://www.youtube.com/watch?v=Ywtt7IRk0V8"
              class="video-popup wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
              ><i class="lnr lnr-film-play"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Video Promo Section -->

  <!-- Start Pricing Table Section -->
  <div id="pricing" class="section pricing-section">
    <div class="container">
      <div class="section-header">
        <h2 class="section-title">{{ $t("home.info.usefulInformation") }}</h2>
        <hr class="lines" />
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-xs-12 mt-4" hidden>
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>Розклад оглядових екскурсій</h2>
              <p class="px-2">
                Пт - 17:00
                <br />
                Сб - 13:00; 15:00; 17:00
                <br />
                Нд - 11:00; 13:00; 15:00; 17:00
              </p>
              Рейс відбувається за наявності 10 дорослих пасажирів.
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12 mt-4" hidden>
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>Денні рейси</h2>
              <p class="px-2">
                Вартість квитка на денні рейси: 11:00; 13:00; 15:00
              </p>
              <ul class="prices">
                <li>Дорослий<b> 190 грн</b></li>
                <li>Пільговий<b> 170 грн</b></li>
                <li>Дитячий 6-14 років<b> 130 грн</b></li>
                <li class="additional-info">
                  <div>
                    Сім’я (2+1)
                    <i class="fa fa-info-circle additional-info__trigger">
                      <div class="additional-info__content">
                        Квиток "Сім'я 2 + 1" передбачає двоє дорослих (МАМА і
                        ТАТО) і дитина до 14 років.
                      </div>
                    </i>
                  </div>
                  <b> 400 грн</b>
                </li>
                <li class="additional-info">
                  <div>
                    Сім’я (2+2)
                    <i class="fa fa-info-circle additional-info__trigger">
                      <div class="additional-info__content">
                        Квиток "Сім'я 2 + 2" передбачає двоє дорослих (МАМА І
                        ТАТО) і двоє дітей, які не досягли 14 років.
                      </div>
                    </i>
                  </div>
                  <b> 500 грн</b>
                </li>
                <li class="additional-info">
                  <div>
                    Сім’я (2+3)
                    <i class="fa fa-info-circle additional-info__trigger">
                      <div class="additional-info__content">
                        Квиток "Сім'я 2 + 3" передбачає двоє дорослих (МАМА І
                        ТАТО) і троє дітей, які не досягли 14 років.
                      </div>
                    </i>
                  </div>
                  <b> 550 грн</b>
                </li>
                <br />
                Нагадуємо, що діти до 5 років катаються в наших автобусах
                безкоштовно!
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12 mt-4" hidden>
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>Вечірні рейси</h2>
              <p class="px-2">Вартість квитка на вечірні рейси: 17:00; 19:00</p>
              <ul class="prices">
                <li>Дорослий<b> 240 грн</b></li>
                <li>Пільговий<b> 190 грн</b></li>
                <li>Дитячий 6-14 років<b> 150 грн</b></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12 mt-4" hidden>
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>Пільговий квиток</h2>
              <p class="px-2">На пільговий квиток мають право:</p>
              <ul>
                <li>Студенти</li>
                <li>Пенсіонери</li>
                <li>Люди з інвалідністю 2-3 груп</li>
                <li>Учасники АТО/ООС</li>
              </ul>
            </div>
            <div class="plan-button">
              <a href="tel:+380661690379" class="btn btn-common mt-2"
                >Документ обов’язковий</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12 mt-4" hidden>
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>Безкоштовні екскурсії</h2>
              <p class="px-2">Право на безкоштовну екскурсію мають:</p>
              <ul>
                <li>діти до 5 років (на руках у батьків)</li>
                <li>Особи віком 75 років і старші</li>
                <li>
                  Люди з інвалідністю 1 групи + 1 супроводжуючий
                </li>
              </ul>
              <small>* 3 туриста за рейс при наявності вільних місць</small>
              <div class="plan-button">
                <a class="btn btn-common mt-2">Документ ОБОВ’ЯЗКОВИЙ</a>
              </div>
            </div>
            <!--<div class="plan-button">
                            <a href="#" class="btn btn-common btn-common__inverted">Buy Now</a>
                        </div>-->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12 mt-4">
          <div class="pricing-table h-100">
            <div class="pricing-details">
              <h2>{{ $t("home.info.weOpenforYou") }}</h2>
              <p class="px-2" v-html="$t('home.info.weOpenforYouContent')"></p>
            </div>
            <div class="plan-button">
              <a
                href="mailto:buspassua@gmail.com"
                class="btn btn-common mt-2"
                >{{ $t("buttons.contact") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Pricing Table Section -->

  <!-- Counter Section Start -->
  <div class="counters section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="facts-item">
            <div class="icon">
              <i class="lnr lnr-clock"></i>
            </div>
            <div class="fact-count">
              <h3>
                <span class="counter">1.5</span> {{ $t("home.info.hours") }}
              </h3>
              <h4>{{ $t("home.info.durationTrip") }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="facts-item">
            <div class="icon">
              <i class="lnr lnr-map"></i>
            </div>
            <div class="fact-count">
              <h3><span class="counter">22</span> {{ $t("home.info.km") }}</h3>
              <h4>{{ $t("home.info.lengthTrip") }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="facts-item">
            <div class="icon">
              <i class="lnr lnr-bus"></i>
            </div>
            <div class="fact-count">
              <h3><span class="counter">15</span>{{ $t("home.info.min") }}</h3>
              <h4>{{ $t("home.info.StopForPhoto") }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="facts-item">
            <div class="icon">
              <i class="lnr lnr-user"></i>
            </div>
            <div class="fact-count">
              <h3><span class="counter">50</span></h3>
              <h4>{{ $t("home.info.seatsCount") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Counter Section End -->

  <!-- Team section Start -->
  <section id="team" class="section">
    <div class="container">
      <div class="section-header">
        <h2 class="section-title">{{ $t("home.team.title") }}</h2>
        <hr class="lines" />
        <p class="section-subtitle">
          {{ $t("home.team.description") }}
        </p>
      </div>
      <div class="row justify-content-center">
        <TeamMember
          :image="'inna.jpg'"
          :name="$t('home.team.members.3.name')"
          :description="$t('home.team.members.3.description')"
        />
        <TeamMember
          :image="'sasha.jpg'"
          :name="$t('home.team.members.1.name')"
          :description="$t('home.team.members.1.description')"
        />
        <TeamMember
          :image="'tania.jpg'"
          :name="$t('home.team.members.2.name')"
          :description="$t('home.team.members.2.description')"
        />
      </div>
    </div>
  </section>
  <!-- Team section End -->
</template>

<script>
import TeamMember from "@/components/team-member/Team-Member.vue";
import Slider from "@/components/slider/Slider.vue";
import "vue3-carousel/dist/carousel.css";
import { getTripTypes } from "@/services/api";
import TripType from "@/components/trip-type/TripType.vue";

export default {
  name: "Home",
  components: {
    TripType,
    TeamMember,
    Slider
  },
  data() {
    return {
      sliderImages: [
        "/img/carousel/new/IMG_3681.webp",
        "/img/carousel/new/IMG_3709.webp",
        "/img/carousel/new/IMG_3731.webp",
        "/img/carousel/new/IMG_3736.webp",
        "/img/carousel/new/IMG_3848.webp",
        "/img/carousel/bus-8.jpg",
        "/img/carousel/bus11.jpg",
        "/img/carousel/bus15.jpg",
        "/img/carousel/bus16.jpg",
        "/img/carousel/tourists-8.jpg",
        "/img/carousel/tourists10.jpg",
        "/img/carousel/tourists11.jpg",
        "/img/carousel/tourists13.jpg",
        "/img/carousel/tourists14.jpg",
        "/img/carousel/tourists15.jpg",
        "/img/carousel/tourists16.jpg",
        "/img/carousel/tourists-6.jpg",
        "/img/carousel/tourists17.jpg"
      ],
      type: "bus",
      isPortfolioSliderVisible: false,
      tripTypes: []
    };
  },
  methods: {
    openSlider(type) {
      this.type = type;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isPortfolioSliderVisible = true;
    }, 3000);

    getTripTypes().then(res => {
      this.tripTypes = res.data.filter(tripType => tripType.visible);
    });
  }
};
</script>

<style lang="scss">
$color1: #f5cc38;
$color2: #cb2a8a;

.prices {
  li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
  }
}

.additional-info {
  position: relative;
  &__trigger {
    &:hover {
      & .additional-info__content {
        display: block;
      }
    }
  }

  &__content {
    display: none;
    position: absolute;
    width: 300px;
    height: auto;
    padding: 15px 10px;
    top: 0;
    left: 0;
    border: 1px solid #e4e2e2;
    background: white;
    z-index: 1;
    color: black;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 600px) {
      width: 250px;
    }
  }
}
</style>
