<template>
  <div v-if="tripInfo && booking" class="container pt-3 pt-lg-5 pb-2">
    <h1 class="text-dark">
      {{ $t("buyTickets.bookingInfo.title") }}
      <b>{{ tripInfo.tripTypeInfo.name }}</b>
    </h1>
    <div class="d-flex justify-content-between mt-2 p-4 flex-wrap">
      <div>
        <div class="d-flex flex-column">
          <span
            ><b>{{ $t("buyTickets.bookingInfo.dateTrip") }}</b> -
            {{ tripDate }}</span
          >
          <span
            ><b>{{ $t("buyTickets.bookingInfo.passenger") }}</b> -
            {{ booking.surname }} {{ booking.name }}</span
          >
          <span
            ><b>{{ $t("buyTickets.bookingInfo.telephone") }}</b> -
            {{ booking.phone }}</span
          >
          <span
            ><b>{{ $t("buyTickets.bookingInfo.countSeats[0]") }}</b> -
            {{ booking.purchasedTickets.adult }}</span
          >
          <span v-if="booking.purchasedTickets.child"
            ><b>{{ $t("buyTickets.bookingInfo.countSeats[1]") }}</b> -
            {{ booking.purchasedTickets.child }}</span
          >
        </div>
        <div class="mt-3 d-flex flex-column">
          {{ $t("buyTickets.bookingInfo.place") }}
          <iframe
            class="address-iframe mt-2"
            title="BUSPASS on google maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d774.5096282186262!2d28.483008581206995!3d49.23603430040083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5bd2b1bf7775%3A0x852c0e2012cef2d7!2sBUS%20PASS!5e0!3m2!1sru!2sua!4v1705849681673!5m2!1sru!2sua"
            style="border:0;"
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div v-if="qrCode">
        <span>{{ $t("buyTickets.bookingInfo.qr") }}</span>
        <a @click="onQrCodeClick($event)" :href="qrCode" class="d-block mt-2">
          <img :src="qrCode" v-if="qrCode" alt="qrcode" width="300" />
        </a>
        <span v-if="booking.status !== 'Open'" class="text-danger">{{
          $t("buyTickets.bookingInfo.alreadyActivatedTicket")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { getBookingInfo, getTripById } from "@/services/api";
import { getDateAndTime } from "@/services/date";

export default defineComponent({
  name: "BookingInfo",
  props: {
    bookingId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const booking = ref();
    const qrCode = ref();
    const tripInfo = ref();

    onMounted(async () => {
      try {
        const bookingInfoResponse = await getBookingInfo(props.bookingId);

        if (!bookingInfoResponse.status === "success") {
          throw new Error("success false");
        }

        booking.value = bookingInfoResponse.data.booking;
        qrCode.value = bookingInfoResponse.data.qrCode;

        const tripResponse = await getTripById(booking.value.tripId);
        tripInfo.value = tripResponse.data;
      } catch (e) {
        console.error("cant resolve booking");
      }
    });

    const tripDate = computed(() => {
      if (!tripInfo.value) {
        return "";
      }

      return getDateAndTime(tripInfo.value.date);
    });

    function onQrCodeClick(e) {
      e.preventDefault();

      const w = window.open("about:blank");
      const image = new Image();
      image.src = qrCode.value;
      setTimeout(function() {
        w.document.write(image.outerHTML);
      }, 0);
    }

    return {
      booking,
      qrCode,
      tripInfo,
      tripDate,
      onQrCodeClick
    };
  }
});
</script>

<style scoped>
.address-iframe {
  width: 400px;
  height: 300px;
  max-width: 100%;
}
</style>
