<template>
  <div class="LiqPay">
    <Loader v-if="isLoading" />
    <section ref="liqpayContainer" class="liqpay-container w-100"></section>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { sha1 } from "@/services/payments";
import { liqPayPrivateKey, liqPayPublicKey } from "@/services/api";
import Loader from "@/components/loader/Loader.vue";

export default defineComponent({
  name: "LiqPay",
  components: { Loader },
  props: {
    paymentData: {
      type: Object,
      default: () => ({})
    },
    amount: {
      type: Number,
      required: true
    }
  },
  emits: ["liqpay-callback"],
  setup(props, { emit }) {
    const liqpayContainer = ref();

    const isLoading = ref(true);

    const privateKey = liqPayPrivateKey;
    const data = JSON.stringify({
      version: 3,
      public_key: liqPayPublicKey,
      action: "pay",
      amount: props.amount,
      currency: "UAH",
      info: JSON.stringify(props.paymentData),
      order_id: props.paymentData.id,
      description: "tickets"
    });

    onMounted(() => {
      liqpayContainer.value.innerHTML = `<div id="liqpay_checkout"></div>`;

      // eslint-disable-next-line no-undef
      LiqPayCheckout.init({
        data: btoa(data),
        signature: btoa(
          sha1(privateKey + btoa(data) + privateKey, {
            asString: true
          })
        ),
        embedTo: "#liqpay_checkout",
        language: "uk",
        mode: "embed",
        description: "Квитки Bus Pass"
      })
        .on("liqpay.ready", () => {
          isLoading.value = false;
        })
        .on("liqpay.callback", liqpayRes => {
          emit("liqpay-callback", liqpayRes);
        });
    });

    return {
      liqpayContainer,
      isLoading
    };
  }
});
</script>
<style lang="scss" scoped></style>
