<template>
  <select
    v-model="$i18n.locale"
    class="ml-2 pl-1 text-center"
    @change="onLanguageSelection($event)"
  >
    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
      {{ lang }}
    </option>
  </select>
</template>
<script>
import { localeList } from "../../localization/l18n";

export default {
  name: "Language-Switcher",
  data() {
    return { langs: localeList };
  },
  computed: {},
  methods: {
    onLanguageSelection: e => {
      localStorage.setItem("locale", e.target.value);
    }
  }
};
</script>
<style scoped lang="scss">
select,
option {
  text-transform: uppercase;
}
</style>
