import { countryCodePhoneLengthMap } from "@/services/constants";

export function validatePhoneLengthByCountryCode(phoneNumber) {
  const phonePrefix = Object.keys(countryCodePhoneLengthMap).find(phonePrefix =>
    phoneNumber.startsWith(phonePrefix)
  );

  if (!phonePrefix) {
    return false;
  }

  const phoneNumberWithoutCountryCode = phoneNumber.slice(phonePrefix.length);
  const requiredPhoneLength = countryCodePhoneLengthMap[phonePrefix];

  return typeof requiredPhoneLength === "object"
    ? requiredPhoneLength.includes(phoneNumberWithoutCountryCode.length)
    : phoneNumberWithoutCountryCode.length === requiredPhoneLength;
}
