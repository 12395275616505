<template>
  <Header />
  <main>
    <router-view />
  </main>
  <Footer />
</template>
<style lang="scss">
@import "./assets/scss/main";
@import "./assets/scss/responsive";
</style>
<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>
<style scoped>
main {
  min-height: 84vh;
}
</style>
