<template>
  <div class="col-lg-4 col-md-6 col-xs-12 mt-4">
    <div class="single-team ">
      <img class="" :src="'img/team/' + image" alt="" loading="lazy" />
      <div class="team-details h-100">
        <div class="team-inner">
          <h4 class="team-title">{{ name }}</h4>
          <p v-html="description" class="mt-2 mb-0"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Team-member",
  props: ["name", "description", "image"]
};
</script>
<style scoped lang="scss">
.team-inner > p span {
  margin: 0 3px;
}
</style>
