import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import BuyTicket from "../views/BuyTicket";
import BookingInfo from "@/views/BookingInfo.vue";
import Terms from "@/views/Terms.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/b/:bookingId",
    name: "BookingInfo",
    component: BookingInfo,
    props: true
  },
  {
    path: "/buy-ticket",
    name: "Buy Ticket",
    component: BuyTicket
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms
  },
  { path: "/:pathMatch(.*)*", redirect: "/" }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { top: 0 };
  }
});

export default router;
