import { createI18n } from "vue-i18n";
import uaLocale from "./locales/ua";
import enLocale from "./locales/en";

const currentLocaleStored = localStorage.getItem("locale");
export const localeList = ["ua", "en"];

// 2. Create i18n instance with options
export const i18n = createI18n({
  warnHtmlInMessage: "off",
  locale: currentLocaleStored || localeList[0], // set locale
  fallbackLocale: currentLocaleStored
    ? localeList.filter(locale => locale !== currentLocaleStored)[0]
    : localeList[1],
  messages: {
    en: enLocale,
    ua: uaLocale
  }
});
