export default {
  buttons: {
    buy: "Buy",
    reserve: "RESERVE",
    pay: "Pay",
    back: "Back",
    contact: "Contact us"
  },
  tickets: {
    child: "Child (7-14 years)",
    usual: "Standard",
    discount: "Discount"
  },
  header: {
    home: "Home",
    services: "Program",
    features: "FAQ",
    map: "Map",
    portfolios: "Photos",
    info: "Information",
    team: "Team",
    contact: "Contact",
    personal: "My Tickets",
    login: "Login",
    logout: "Logout"
  },
  home: {
    intro: {
      title: "BusPass - the best sightseeing tour of Vinnytsia",
      description: `on a double-decker convertible bus <br> with a professional guide`
    },
    services: {},
    programs: {
      title: "PROGRAM",
      description: "The schedule is updated every Monday"
    },
    map: {
      title: "Route of our tours"
    },
    features: {
      title: "Advantages of the Hop-on Hop-off system",
      sub_title: `Hop-on hop-off tour is a guided tour on a predefined route with designated stops.
                    <br>
                    During the ticket's validity period (48 hours), the ticket holder can hop on and off the bus an unlimited number of times.
                    <br>
                    The ticket is activated after the first use, not from the moment of purchase, allowing for advance purchase.`,
      items: [
        {
          title: "7 Stops",
          description: "Ability to get on/off at any stop"
        },
        {
          title: "Online Purchase",
          description:
            "Buy an electronic ticket on the website and activate it on the bus"
        },
        {
          title: "48 Hours Validity",
          description: "Use the bus for 48 hours from the moment of activation"
        },
        {
          title: "Freedom of Movement",
          description: "Not restricted to a group like regular bus tours"
        }
      ]
    },
    portfolios: {
      title: "Photo Gallery"
    },
    info: {
      currentQuestions: "Actual questions",
      whereStart: "Where does the tour start?",
      startTrip:
        "The beginning of the route is opposite the Central bus station.",
      wherePay: "Where to buy a ticket?",
      payWith:
        "Here on the site. Also, if there are free seats, you can buy tickets immediately before boarding the bus.",
      accessTrip:
        "Is How to book seats on the second floor? accessible travel for groups of people with reduced mobility?",
      accessTripAnswer:
        "All tickets on sale are seats on the second floor, but without numbering, boarding is free",
      currentSchedule: "HOW TO FIND OUT THE CURRENT SCHEDULE?",
      currentScheduleAnswer: `The schedule is updated every Monday. Information can be found on our social networks and website.`,
      usefulInformation: "USEFUL INFORMATION",
      weOpenforYou: "WE ARE OPEN FOR YOU",
      weOpenforYouContent: `Friends! We are launching such a project for the first time, and like everyone else
                 develops, we are not immune to mistakes.
                 <br />
                 If you encounter bad service, write to us
                 buspassua{'@'}gmail.com and the issue will be resolved as soon as possible
                 terms
                 <br />
                 Thank you for being with us!`,
      hours: "hours",
      km: "km",
      min: "min",
      durationTrip: "Duration of the trip",
      lengthTrip: "The length of the route",
      StopForPhoto: "Stop for a photo",
      seatsCount: "Places on the second floor"
    },
    team: {
      title: "Our team",
      description:
        "In order for your trip to be safe, comfortable, interesting and fun, we have assembled a team of real professionals! So, meet:",
      members: [
        {
          name: "Alona, Guide",
          description: `After spending 8 years with a mammoth <span>(not to be confused with a dinosaur)</span> at the Vinnytsia Local Lore Museum <span>(if you haven't been there yet, make sure to visit, it's still there)</span>, Alona has perfected her knowledge of Vinnytsia to the highest level.</br> She adores aesthetics in everything, taking photos, and being photographed. And if you're on our tour alone, she will gladly take cool photos and stories with a splendid view of the city for you!`
        },
        {
          name: "Alexander, Boss",
          description:
            "The heart and soul of our project. Went from a smartphone to a double-decker. Can drive anything that moves! Has driving experience in left-hand traffic in the UK and Japan, Norwegian fjord serpentine roads, and off-road trails in the Khmelnytskyi region. Loves football and 'Romashka' candies."
        },
        {
          name: "Tetiana, Manager",
          description:
            "Responsible for everything from answering calls to delivering alcohol on adult tours. Didn't study history in school; now catching up. Constantly in the process of generating new ideas. A bit quirky at work."
        },
        {
          name: "Inna, Guide",
          description: `She came to us from hospitable Georgia, a graduate of the Institute of History, Ethnology, and Law, as well as the "Vinnitsa Guide School".</br>Undoubtedly, the liveliest person on our team, always up for any excitement and filled with ideas and energy.</br>She cooks wonderfully and often brings something tasty, which is why we love her so much.`
        }
      ]
    },
    contact: {}
  },
  footer: {
    title: "Contact With us",
    city: "Legal address: Vinnytsia, st.Agrarna 5/16 21019",
    schedule: "Schedule: everyday from 8:00 a.m.to 8:00 p.m"
  },
  buyTickets: {
    accordion: [
      {
        title: "Who is entitled to free transportation?",
        content: ` <ol>
                        <li> Children up to 5 years old</li>
                        <li> Persons aged 75 and older</li>
                        <li> Disabled individuals (group 1) + 1 accompanying person</li>
                    </ol>`
      },
      {
        title: "Who is entitled to discounted transportation?",
        content: `<ol>
                        <li> Students</li>
                        <li> Pensioners</li>
                        <li> Disabled individuals (groups 2-3)</li>
                        <li> ATO participants</li>
                    </ol>`
      }
    ],
    calendar: {
      title: "Travel calendar",
      noItemsMessage:
        "There are currently no active trips, stay tuned for updates on the website and <a href='https://www.instagram.com/bus.pass/'>Instagram</a>. The schedule is updated every Monday.",
      descr:
        "All prices are per passenger including taxes.The schedule indicates the local time of departure cities. Tickets cannot be exchanged or returned",
      typeTickets: ["Adult ticket", "Children's ticket"],
      currency: "UAH.",
      leftTickets: "Seats left on second floor:"
    },
    formForPay: {
      title: "Fill out the form",
      name: "Name",
      surname: "Surname",
      telephone: "Phone number",
      errorForPhone:
        "The phone number must start with +, contain country code and valid length",
      countSeats: ["Number of adult seats", "Number of child seats"],
      childToolTip: "From 5 till 12 years",
      agreeWithTerms: `By clicking pay you agree with <a href="./terms"> Terms </a>`,
      countTickets: "Number of tickets:",
      total: "Total",
      maxSeatsError: "You have added the maximum number of free seats"
    },
    bookingInfo: {
      title: "You have booked a trip",
      dateTrip: "Date of trip",
      passenger: "passenger",
      telephone: "Contact phone number",
      countSeats: ["Adult tickets", "Children's tickets"],
      place: "The stop is located at: 8 Kyivska Street, Vinnytsia",
      qr: "To board, present the QR code: ",
      alreadyActivatedTicket: "Your booking is already activated!"
    }
  },
  weekDays: {
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat"
  },
  months: {
    Jan: "Jan",
    Feb: "Feb",
    Mar: "Mar",
    Apr: "Apr",
    May: "May",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec"
  }
};
