const isProd = true;
export const liqPayPublicKey = isProd ? "i40891977828" : "sandbox_i51664074254";
export const liqPayPrivateKey = isProd
  ? "0R0WSTbcupIpS9OQ4I8nelSXbyilBuVOpOjMQWL8"
  : "sandbox_ibkOLG1hoR3OTk7ZJ4mwRKorbyhcMfUDrGU5Ue16";
const API_URL = isProd
  ? "https://us-central1-bus-pass-307715.cloudfunctions.net/app"
  : "http://127.0.0.1:5001/bus-pass-307715/us-central1/app";

export function getTripTypes() {
  return fetch(`${API_URL}/trip-types/all`).then(res => res.json());
}

export function getTripsForSpecificDate(dates) {
  const [startDate, endDate] = dates;
  const params = new URLSearchParams();
  params.set("endDate", endDate);
  params.set("startDate", startDate);

  return fetch(`${API_URL}/trip/filter?` + params).then(res => res.json());
}

export function createBooking(payload) {
  return fetch(`${API_URL}/bookings`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
}

export function getBookingInfo(bookingId) {
  return fetch(`${API_URL}/bookings?id=${bookingId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(res => res.json());
}

export function getTripById(tripId) {
  return fetch(`${API_URL}/trip?id=${tripId}`).then(res => res.json());
}
