<template>
  <div class="container">
    <Loader v-if="isLoading" />
    <h1>{{ $t("buyTickets.calendar.title") }}</h1>
    <div class="calendar">
      <div class="week" v-if="selectedDate">
        <div
          v-for="(day, index) in currentWeek"
          :key="day.date"
          class="day"
          @click="selectDay(index)"
          :class="{ 'no-trips': !currentWeekTrips[index].length }"
        >
          <div
            :class="{
              selected: isDaySelected(index)
            }"
          >
            <span class="inner-content"
              >{{ $t("weekDays." + day.dayOfWeek) }}, {{ day.day }}<br />{{
                $t("months." + day.shortMonth)
              }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="TripDateSelector" v-if="selectedDate">
      <div v-for="trip in selectedDayTrips" :key="trip.id" class="w-100 trips">
        <div class="card bg-white cardTrip container">
          <div class="containerCard card-body">
            <div class="dateTrip">
              <div>
                {{ $t("weekDays." + selectedDate.dayOfWeek) }},
                {{ selectedDate.day }}
                {{ $t("months." + selectedDate.shortMonth) }}
              </div>
              <p
                class="font-weight-bold"
                style="font-size: 30px; margin-bottom: 0"
              >
                {{ formatTripDate(trip.date) }}
              </p>
            </div>
            <div style="min-width: 200px;padding: 10px 0;">
              <p class="trip-name font-weight-bold">
                {{ trip.tripTypeInfo.name }}
              </p>
              <p class="text-center mb-0">
                {{ $t("buyTickets.calendar.leftTickets") }}
                {{ trip.availableSeats }}
              </p>
            </div>
            <div class="prices">
              <div>
                <p>{{ $t("buyTickets.calendar.typeTickets[0]") }}</p>
                <p class="lead">
                  {{ trip.tripTypeInfo.prices.adult }}
                  {{ $t("buyTickets.calendar.currency") }}
                </p>
              </div>
              <div class="ml-2">
                <p>{{ $t("buyTickets.calendar.typeTickets[1]") }}</p>
                <p class="lead">
                  {{ trip.tripTypeInfo.prices.child }}
                  {{ $t("buyTickets.calendar.currency") }}
                </p>
              </div>
            </div>
            <button
              class="btn btn-common"
              @click="$emit('trip-selected', trip)"
            >
              {{ $t("buttons.reserve") }}
            </button>
          </div>
        </div>
      </div>
      <p class="text-muted" v-html="$t('buyTickets.calendar.descr')"></p>
    </div>

    <div
      class="h4 mt-5"
      v-if="showNoTrips"
      v-html="$t('buyTickets.calendar.noItemsMessage')"
    ></div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getTripsForSpecificDate } from "@/services/api";
import { getDayOfWeek, getShortMonth, isToday } from "./utils/utils";
import { format, isWithinInterval, set } from "date-fns";
import Loader from "@/components/loader/Loader.vue";

export default defineComponent({
  name: "TripDateSelector",
  components: { Loader },
  props: {},
  setup() {
    const filteredTrips = ref([]);
    const currentWeek = ref([]);
    const showNoTrips = ref();
    const today = new Date();
    const selectedDate = ref();

    const isLoading = ref(true);

    onMounted(() => {
      generateCurrentWeek();
      getTripForNext2Weeks();
    });

    function setInitialSelectedDate() {
      const firstDayWithTrips = currentWeekTrips.value.findIndex(
        trips => trips.length
      );

      if (firstDayWithTrips !== -1) {
        selectDay(firstDayWithTrips);
      } else {
        showNoTrips.value = true;
      }

      isLoading.value = false;
    }

    const selectedDayTrips = computed(() =>
      getTripsForDay(selectedDate.value.date)
    );

    const currentWeekTrips = computed(() =>
      currentWeek.value.map(day => getTripsForDay(day.date))
    );

    const generateCurrentWeek = () => {
      const week = [];
      for (let i = 0; i < 31; i++) {
        const day = new Date(today);
        day.setDate(today.getDate() + i);
        week.push({
          date: day,
          day: day.getDate(),
          dayOfWeek: getDayOfWeek(day.getDay()),
          shortMonth: getShortMonth(day.getMonth()),
          selected: isToday(day)
        });
      }

      currentWeek.value = week;
    };

    const selectDay = selectedDayIndex => {
      if (currentWeekTrips.value[selectedDayIndex].length) {
        selectedDate.value = currentWeek.value[selectedDayIndex];
      }
    };

    const isDaySelected = dayIndex =>
      currentWeek.value[dayIndex].date.getTime() ===
      selectedDate.value.date.getTime();

    const getTripsForDay = date => {
      const start = set(date.getTime(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      });
      const end = set(date.getTime(), {
        hours: 24,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
        date: date.getDate()
      });

      return filteredTrips.value.filter(trip =>
        isWithinInterval(trip.date, {
          start,
          end
        })
      );
    };

    function addMonthToDate(currentDate) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      return currentDate;
    }

    function getTripForNext2Weeks() {
      const nowPlus30Minutes = new Date().getTime() + 30 * 60 * 1000;

      const nowPlusWeek = addMonthToDate(new Date());

      const tripsTimeRange = [
        new Date(nowPlus30Minutes).getTime(),
        nowPlusWeek.getTime()
      ];

      getTripsForSpecificDate(tripsTimeRange).then(trips => {
        filteredTrips.value = trips.data.map(trip => ({
          ...trip,
          formattedDate: new Date(trip.date).toString()
        }));

        setInitialSelectedDate();
      });
    }

    const formatTripDate = date => format(date, "HH:mm");

    return {
      filteredTrips,
      currentWeek,
      selectedDate,
      selectDay,
      currentWeekTrips,
      selectedDayTrips,
      isDaySelected,
      formatTripDate,
      showNoTrips,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
h1 {
  color: #000;
  padding-top: 15px;
}
.trips {
  display: flex;
  justify-content: center;
}
.cardTrip {
  width: 100%;
  margin: 10px 0;
}
.dateTrip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.containerCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calendar {
  width: 100%;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .change-week {
    align-self: flex-end;
    font-size: 0.875rem;
    line-height: 1.5;

    @media (min-width: 991px) {
      width: unset;
    }
  }
}

.day {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  font-size: 18px;

  @media (max-width: 1024px) {
    width: calc(100% / 7);
  }
}

.selected {
  .inner-content {
    border: 2px solid #c6007e;
    box-sizing: border-box;
  }
}

.week {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 20px;
  height: 60px;
  box-sizing: border-box;
}

.date {
  font-size: 20px;
}

.dayOfWeek {
  font-size: 14px;
  font-weight: bold;
}

.shortMonth {
  font-size: 12px;
  color: #888;
}

.prices {
  display: flex;
  gap: 10px;

  p {
    font-weight: 600;
  }

  div p:nth-child(2) {
    color: #c6007e;
  }
}

@media (max-width: 991px) {
  .containerCard {
    flex-direction: column;
  }

  .cardTrip {
    margin: 10px 0;
  }

  .containerCard {
    flex-direction: column;
    align-items: stretch;
  }

  .font-weight-bold {
    font-size: 20px;
  }

  .btn-common {
    width: 100%;
  }

  .prices {
    justify-content: space-around;
  }
}

.no-trips {
  opacity: 0.5;
  cursor: not-allowed;
  color: #c6c6c6;
  text-decoration: line-through;
  display: none;
}

.trip-name {
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  text-overflow: ellipsis;
}
</style>
