<template>
  <div class="pt-5">
    <TripDateSelector
      @trip-selected="onTripSelected"
      v-if="steps.tripSelector"
    ></TripDateSelector>
    <BookingDetailForm
      @booking-details-changed="onBookingDetailsSelected"
      v-if="steps.bookingDetails"
      :trip="selectedTrip"
      @steps-to-back="onClickToBackTripSelected"
    ></BookingDetailForm>

    <LiqPay
      v-if="steps.payment"
      @liqpay-callback="handlePayment"
      :amount="bookingData.amount"
      :payment-data="bookingData"
    >
    </LiqPay>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import TripDateSelector from "@/components/TripDateSelector.vue";
import { createBooking } from "@/services/api";
import LiqPay from "@/components/LiqPay.vue";
import BookingDetailForm from "@/components/BookingDetailForm.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BuyTicket",
  components: { TripDateSelector, LiqPay, BookingDetailForm },
  setup() {
    const liqpayContainer = ref();
    const selectedTrip = ref({});
    const router = useRouter();
    const steps = reactive({
      tripSelector: true,
      bookingDetails: false,
      payment: false
    });

    const bookingData = ref({
      tripId: ""
    });

    function handlePayment(liqpayRes) {
      if (liqpayRes.status === "success") {
        router.push({
          name: "BookingInfo",
          params: {
            bookingId: bookingData.value.id
          }
        });
      }
    }

    function onTripSelected(trip) {
      steps.tripSelector = false;
      steps.bookingDetails = true;
      bookingData.value.tripId = trip.id;
      selectedTrip.value = trip;
    }

    function onBookingDetailsSelected(bookingDetails) {
      bookingData.value = {
        tripId: bookingData.value.tripId,
        name: encodeURIComponent(bookingDetails.firstname),
        surname: encodeURIComponent(bookingDetails.lastname),
        phone: bookingDetails.phoneNumber,
        purchasedTickets: {
          adult: bookingDetails.adultTickets,
          child: bookingDetails.childTickets
        },
        amount: bookingDetails.amount
      };

      createBooking(bookingData.value)
        .then(res => res.json())
        .then(res => {
          if (res.status) {
            bookingData.value.id = res.data.id;
            steps.bookingDetails = false;
            steps.payment = true;
          }
        });
    }

    const onClickToBackTripSelected = () => {
      steps.tripSelector = true;
      steps.bookingDetails = false;
    };

    return {
      onTripSelected,
      liqpayContainer,
      steps,
      handlePayment,
      bookingData,
      onBookingDetailsSelected,
      selectedTrip,
      onClickToBackTripSelected
    };
  }
});
</script>

<style lang="scss" scoped></style>
